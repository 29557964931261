@import './icon';

.alignCenter {
  align-self: center;
}

.alignItemsCenter {
  align-items: center;
}

.bgGrayLight {
  background: var(--color-white11);
}

.p {
  &_0 {
    padding: 0 !important;
  }
}

.p_lf {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.jusbeetwenPicker {
  justify-content: space-between !important;
}


.mt {
  &_5 {
    margin-top: 5px;
  }

  &_10 {
    margin-top: 10px;
  }

  &_15 {
    margin-top: 15px;
  }

  &_25 {
    margin-top: 25px;
  }
}

.mb {
  &_5 {
    margin-bottom: 5px;
  }

  &_15 {
    margin-bottom: 15px;
  }

  &_25 {
    margin-bottom: 25px;
  }
}

.ml {
  &_5 {
    margin-left: 5px;
  }

  &_10 {
    margin-left: 10px;
  }
}

.mr {
  &_5 {
    margin-right: 5px;
  }

  &_10 {
    margin-right: 10px;
  }
}

.rtl {
  direction: rtl !important;
}

.ltr {
  direction: ltr !important;
}

.font {
  &-10 {
    font-size: 10px !important;
  }

  &-11 {
    font-size: 11px !important;
  }


  &-12 {
    font-size: 12px !important;
  }

  &-14 {
    font-size: 14px !important;
  }
}

.mxW50 {
  max-width: 50%
}

.brNone {
  border: none !important;
}

.bgNone {
  background: none !important;
}

.color {
  &_gray {
    color: var(--color-gray10) !important;
  }

  &_red {
    color: var(--color-red2) !important;
  }

  &_green {
    color: var(--color-green1) !important;
  }
}

.colorText {
  color: var(--color-black4);
}

.cPointer {
  cursor: pointer;
}


.loadingList {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.reverse {
  flex-direction: column-reverse !important;
}

.txtforceGateway {
  font-weight: 10px;
  color: var(--color-red5);
  font-weight: 400;
  padding-right: 2px;
}

.warningOrderFast {
  width: 100%;
  margin: 5px 0;
  padding: 15px 25px 5px;

  p {
    font-size: 12px;
    font-weight: 400;
    color: var(--color-red3);
    position: relative;
    padding-right: 15px;

    &::before {
      position: absolute;
      top: 0;
      right: 0px;
      content: "*";
      color: var(--color-red2);
      font-size: 14px;
      font-weight: 500;
    }
  }
}

.posRelative {
  position: relative;
}

.vouBox {
  position: relative;
  padding: 10px 0;

  .brBox {
    position: absolute;
    width: calc(100% + 30px);
    right: -15px;
    top: 5px;
    height: calc(100% - 10px);
    box-shadow: 0px 1px 2px 1px var(--color-white10);
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    z-index: 1;
  }

  >section {
    position: relative;
    z-index: 2 !important;
    padding: 0;

    &.bx1 {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      >div {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 5px;

      }
    }

    .t1 {
      overflow: unset;
      text-overflow: unset;
    }
  }
}

.inData:has(.support-iframe) {
  margin-top: 5px;
}

.support-iframe {
  height: 100%;
  border: none;
}

.divderCardBank {
  width: 85%;
  margin: 20px auto 5px auto;
  border-bottom: 1px dashed var(--color-gray5);
}

.textNewTab {
  position: absolute;
  left: -5px;
  top: -15px;
  padding: 0 3px;
  background-color: var(--color-red4);
  color: var(--color-Onlywhite);
  font-size: 11px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.cashCardAlert {
  width: 100%;
  font-size: 13px;
  color: var(--color-red1);
  margin-bottom: 20px;
  margin-top: 10px
}

.datePickerSelect {
  width: 100%;
  border: 1px solid var(--color-blueLi2);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px 0;

  >div {
    width: 100%;
  }

  input {
    width: 100%;
    flex: 1 1;
    background: transparent;
    border: none;
    outline: none;
    font-size: 13px;
    text-align: right;
    color: var(--color-black4);

    &:focus {
      border: none;
      box-shadow: none;
    }
  }

}

.MuiTooltip-popper {
  .MuiTooltip-tooltip {
    background: var(--color-gray8);
    font-size: 13px;
    text-align: center;
    color: var(--color-white);
    font-family: YekanBakhOlny;
    font-weight: 400;
  }

  .MuiTooltip-arrow {
    color: var(--color-gray8);
  }
}

.arrowOrderBook {
  position: relative;

  &::after {
    position: absolute;
    top: 50%;
    left: -7px;
    content: "⯈";
    font-size: 12px;
    color: var(--color-gray10);
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
  }
}


.changeSound {
  width: 100%;
  padding: 5px 10px;
  margin: 5px 0 10px 0;

  .box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 3px;
    padding: 10px;
    border: 1px solid var(--color-white16);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;

    .inSecurity {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .switcher {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 0;
        padding: 5px 10px;

        &::before {
          display: none;
        }
      }
    }

  }

  .t1 {
    font-size: 13px;
    font-weight: 400;
    color: var(--color-black4);
  }
}


.addSelectAdressBook {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  .myInput {
    flex: 1;
  }



  .addressbook {
    border: 1px solid var(--color-blueLi2);
    padding: 12px 2px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    cursor: pointer;

    i::before {
      content: '';
      // background: url("../svg/mIcon_addressBook.svg") no-repeat center / contain;
      // width: 22px;
      // height: 22px;
      // display: flex;

      margin: 0 13px;
      display: flex;
      mask-image: url('../svg/mIcon_addressBook.svg');
      background-color: var(--color-blueToWhite);
      mask-repeat: no-repeat;
      mask-position: center;
      width: 15px;
      height: 15px;
      padding: 6px;
    }
  }
}

.minWidth {
  &-37 {
    min-width: 37px !important;
  }
}

.optionList:has(.itemOptionForm) {
  padding: 8px;
}

.itemOptionForm {
  display: flex;
  width: 100%;
  min-width: 150px;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  padding: 5px 12px;
  margin-bottom: 3px;
  border-bottom: 1px solid var(--color-blueLi2);

  &:last-child {
    border: none;
  }

  &:hover {
    background: var(--color-white8);
  }

  &.select {
    background: var(--color-white5);
  }

  .t2 {
    font-size: 12px;
    padding: 3px 5px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;

    &.active {
      background: var(--color-greenLi2);
      color: var(--color-green4);
    }

    &.deActive {
      background: var(--color-redLi1);
      color: var(--color-red4);
    }
  }
}

.statusNetwork {
  font-size: 12px;
  padding: 3px 6px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  flex: unset !important;
  margin-right: auto;

  &.active {
    background: var(--color-greenLi2);
    color: var(--color-green4);
  }

  &.deActive {
    background: var(--color-redLi1);
    color: var(--color-red4);
  }
}

.updateDialogNEW {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: var(--dialog-bg);
  color: var(--dialog-text);
  box-shadow: 0px 0px 10px var(--color-grayLi1);
  padding: 16px;
  z-index: 1000;
  width: 300px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.feeReceiveBuy {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  p {
    font-size: 13px;
    font-weight: 400;
    color: var(--color-black4);
  }
}

.verifyWithdrawCoin {
  .bodyDialog {
    padding: 15px 20px;
  }
}

.btnsConfirmBuySellFast {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 30px 20px;
  gap: 10px;

  .btnCancel {
    background: var(--color-grayBlue);
    color: var(--color-white);
  }
}

.showNumSymbolMarket {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1px;

  p {
    font-size: 12px;
  }

  .t1 {
    font-size: 11px;
  }
}

.trHover {
  position: relative;

  .hoverOrderBook {
    position: absolute;
    right: -205px;
    width: 200px;
    height: 90px;
    display: none;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    background: var(--color-bghoverOrderBook);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    padding: 10px;

    .itemHover {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 5px;
      width: 100%;

      p {
        color: var(--bg-main);
        font-size: 12px;
      }
    }

    .itemHover:first-child {
      padding-bottom: 8px;
      border-bottom: 1px solid var(--color-divider5);
      padding-bottom: 5px;
    }
  }


  &:hover {
    background: var(--color-hoverDeep);

    .hoverOrderBook {
      display: flex;
    }
  }

  &:hover~.trHover {
    background: var(--color-hoverDeep);
  }
}


.animBell {
  animation: ring 2s linear infinite;
  -webkit-animation: ring 2s linear infinite;
}

.checkBoxConfirmBuySellFast {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  padding: 15px 30px 0px;

  label {
    font-size: 13px;
    color: var(--color-black4);
  }

  .checkBox {
    position: relative;
    cursor: pointer;
    width: 20px;
    height: 20px;

    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 20px;
      height: 20px;
      top: 0;
      left: 0;
    }

    &:checked:before {
      content: "";
      display: block;
      position: absolute;
      width: 20px;
      height: 20px;
      top: 0;
      left: 0;
      background-color: var(--color-blue3);
      border-radius: 3px;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      -ms-border-radius: 3px;
      -o-border-radius: 3px;
    }

    &:checked:after {
      content: "";
      display: block;
      width: 5px;
      height: 10px;
      border: solid var(--color-white);
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
      top: 2px;
      left: 6px;
    }

  }
}

.loadingCircular {
  background: var(--color-blue3);
  color: var(--color-white);
  font-weight: 500;
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  padding: 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;

  circle {
    stroke: var(--color-white);
  }
}

.textInfoDialog {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;

  .t1 {
    font-size: 12px;
    font-weight: 400;
    color: var(--color-black4);
  }
}

.textInfoDialog .iconInfo {
  align-self: flex-start;
  position: relative;
  top: 2px;
  background-color: var(--color-orange);
}

.numBalanceWallet {
  margin: 5px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  gap: 5px;
  background: var(--color-white5);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;

  .item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
  }

  .t1 {
    font-size: 11px;
    color: var(--color-gray9);
    font-weight: 400;
  }

  .t2 {
    font-size: 12px;
    color: var(--color-black4);
    font-weight: 400;
  }
}

.remainderMaxWithdraw {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  margin: 6px 0;

  .t1 {
    font-size: 11px;
    font-weight: 300;
    color: var(--color-gray12);
  }

  .t2 {
    font-size: 11px;
    font-weight: 300;
    color: var(--color-gray12);
  }
}

.iconTxtAlertHistroy {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  .iconCoinPair {
    position: relative;
    margin-left: 5px;
    height: 24px;

    .iconCoin {
      position: absolute;
      top: 0px;
      right: 5px;
      width: 21px;
      height: 21px;
    }

    .iconPair {
      position: relative;
      top: 3px;
      right: -1px;
      width: 19px;
      height: 19px;
    }
  }

}

.datePickerAlert {
  width: 100%;
  border: none;
  display: flex;


  >div {
    width: 100%;
  }

  input {
    width: 100%;
    flex: 1;
    background: transparent;
    border: none;
    outline: none;
    font-size: 13px;
    color: var(--color-black4);
    text-align: right;

    &:focus {
      border: none;
      box-shadow: none;
    }
  }

}

.boxIconSearchHistory {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 10px 0;
  padding: 0px 10px;
  gap: 7px;

  .boxSearch {
    flex: 1;
    background: var(--color-white);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    border: 1px solid var(--color-white5);
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    padding: 15px 13px 16px 26px;

    input {
      width: 100%;
      background: transparent;
      border: none;
      color: var(--color-black4);
    }
  }
}

.datePickerHistory {
  border: none;
}

.boxFilerHistory {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;
  background: var(--color-white);
  padding: 8px 16px;
  padding-left: 12px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;

  .txt1 {
    width: 100%;
    border-bottom: 1px solid var(--color-white5);
    padding-bottom: 12px;
    font-size: 13px;
    color: var(--color-black4);
    font-weight: 500;
    margin-bottom: 5px;
  }

  .boxSearch {
    width: 100%;
    background: var(--color-white1);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    border: 1px solid var(--color-white5);
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    padding: 9px 5px;

    input {
      width: 100%;
      background: transparent;
      border: none;
      color: var(--color-black4);
    }
  }

  .listItemBox {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
    flex-direction: column;
    max-height: 150px;
    width: 100%;
    overflow: auto;
    padding-left: 4px;

    .itemSelectBox {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 12px;
      width: 100%;

      .checkBox {
        position: relative;
        cursor: pointer;
        width: 20px;
        height: 20px;

        &:before {
          content: "";
          display: block;
          position: absolute;
          width: 20px;
          height: 20px;
          top: 0;
          left: 0;
        }

        &:checked:before {
          content: "";
          display: block;
          position: absolute;
          width: 20px;
          height: 20px;
          top: 0;
          left: 0;
          background-color: var(--color-blue3);
          border-radius: 3px;
          -webkit-border-radius: 3px;
          -moz-border-radius: 3px;
          -ms-border-radius: 3px;
          -o-border-radius: 3px;
        }

        &:checked:after {
          content: "";
          display: block;
          width: 5px;
          height: 10px;
          border: solid var(--color-white);
          border-width: 0 2px 2px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
          position: absolute;
          top: 2px;
          left: 6px;
        }

      }

      .t1 {
        font-weight: 500;
        color: var(--color-black4);
      }

      .nameBox {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 12px;

        .icon {
          width: 24px;
          height: 24px;
          object-fit: cover;
        }

        .t2 {
          margin-right: auto;
          color: var(--color-black4);
          font-weight: 500;
        }
      }
    }
  }

  .MuiButtonBase-root {
    &.Mui-checked {
      color: var(--color-blue3);
    }
  }

  .MuiButtonBase-root,
  .MuiButtonBase-root {
    color: var(--color-black4);
  }

  .MuiFormGroup-root {
    .MuiFormControlLabel-root {
      margin: 0;

      .MuiFormControlLabel-label {
        font-size: 12px;
        font-family: YekanBakhOlny;
        color: var(--color-black4);
      }
    }
  }

}

.selectWalletBuy {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
  flex-direction: column;
  margin-top: 25px;
  margin-bottom: 25px;
  width: 100%;

  .MuiButtonBase-root {
    &.Mui-checked {
      color: var(--color-blue3);
    }
  }

  .t1 {
    font-size: 15px;
    font-weight: 500;
    color: var(--color-black4);
  }

  .MuiFormGroup-root {
    gap: 20px;

    .MuiFormControlLabel-root {
      margin: 0;

      .MuiFormControlLabel-label {
        font-size: 12px;
        font-family: YekanBakhOlny;
      }
    }
  }

  .MuiButtonBase-root,
  .MuiButtonBase-root {
    color: var(--color-black4);
  }

  .adressBox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    width: 100%;

    .selectParent {
      width: 100%;
    }

    .mainInput {
      width: 100%;
    }

  }

}

.redioBoxHistoryPayment {
  margin: 20px 0 -10px 0;


  .MuiTypography-root {
    font-family: YekanBakhOlny;
  }

  .MuiFormControlLabel-root {
    margin: 0;
  }

}

.level2Page {
  justify-content: flex-start;
  padding-top: 50px;

  .t {
    font-size: 14px;
    font-weight: 600;
    color: var(--color-black4);

    &::after {
      content: "*";
      color: red;
    }
  }

  .imgSelect {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .textBox {
    width: 100%;
    border: 1px solid var(--color-white9);
    padding: 15px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 50px;

    textarea {
      width: 100%;
      height: 200px;
      resize: none;
      border: none;
      color: var(--color-black4);
      background: transparent;

    }
  }

  .uploadPicture {
    margin-top: 20px;
  }

  .largeButton {
    margin-top: 40px;
    color: var(--color-black4);
  }
}

.iconLinkWithTag {
  display: flex;

  &::after {
    content: "";
    content: '';
    background: url('../svg/m-IconLink2.svg') no-repeat center / contain;
    width: 19px;
    height: 19px;
    margin-left: 5px;
  }
}

.paging {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 15px 0 0 0;
  direction: ltr;
  width: 100%;

  .MuiPagination-ul {
    direction: ltr !important;
  }

  .MuiButtonBase-root {
    border-radius: 8px !important;
    -webkit-border-radius: 8px !important;
    -moz-border-radius: 8px !important;
    -ms-border-radius: 8px !important;
    -o-border-radius: 8px !important;
  }
}

.historyStyleNew {
  justify-content: flex-start;
  background: none;
  padding: 0;

  .tabs span {
    background: var(--color-white);

    &.active {
      background: var(--color-blueLi8);
    }
  }

  .historyDetail {
    height: 100%;
    max-height: calc(100vh - 300px);
    overflow: auto;

    >div {
      background: var(--color-white);
    }
  }
}



.fetchListLoad {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 20px 0;
  background: none !important;

  .emptyLoad {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    width: 100%;

    p {
      font-size: 14px;
      font-weight: 500;
      color: var(--color-black4);
    }

    img {
      width: 85px;
      height: 70px;
    }
  }

}

/////////////verFiyStyle 
.codeNumberBox {
  margin-top: 22px;
  direction: rtl;
}

.character--inactive,
.character--selected {
  font-size: 13px;
}

.containerVerify {
  width: unset !important;
  height: 50px;
  direction: ltr;
}

.characterVerify {
  background: var(--color-white12);
  border: 1px solid var(--color-white12);
  border-radius: 10px;
  padding: 7px;
  font-size: 13px;

}

.character--filled {
  font-size: 13px;
  color: var(--color-black4);
}

:where(.vi__character) {
  height: 50px !important;
  line-height: 40px !important;
}


//////notificationHeader

////////////////////FCM Message Notif
#notificationHeader {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
}

#notificationBody {
  margin-top: 10px;
  text-align: center;
}

#imageContainer {
  display: flex;
  align-items: center;
  height: 100px;
  object-fit: contain;
}

/* ////////////////////// */

.inData.justStart {
  justify-content: flex-start;
}

.inData .topTilteBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.inData .topTilteBox .t1 {
  font-size: 14px;
  color: var(--color-black4);
  line-height: 24px;
  font-weight: 500;
}

.inData .topTilteBox .refreshApi {
  font-size: 14px;
  color: var(--color-black4);
  line-height: 24px;
  font-weight: 400;
  display: flex;
  margin-top: 16px;
}

.inData .topTilteBox .refreshApi::before {
  content: '';

  display: flex;
  mask-image: url('../svg/refresh2.svg');
  background-color: var(--color-black4);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 15px;
  height: 15px;
  margin-left: 5px;
  padding: 6px;
}

.historyList {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 16px;
  margin-top: 15px;

  .itemWithdraw {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    padding: 11.5px 13px;
    border: 1px solid var(--color-white6);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;

    .right {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;

      .icon {
        width: 24px;
        height: 24px;
        object-fit: cover;
      }

      .txtBox {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;

        .t1 {
          color: var(--color-black4);
          font-size: 12px;
          font-weight: 500;
        }

        .t2 {
          color: var(--color-black4);
          font-size: 12px;
          font-weight: 400;
        }
      }


    }

    .left {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      flex-direction: column;
      gap: 4px;

      .t1 {
        font-size: 12px;
        font-weight: 500;
        color: var(--color-black4);
        display: flex;
        margin-left: 4px;
        text-wrap: nowrap;
        white-space: nowrap;

        &::after {
          content: '';
          background: url('../svg/arrowLeft.svg') no-repeat center / contain;
          width: 20px;
          height: 20px;
          margin-right: 5px;
          margin-left: -12px;
        }
      }

      .boxStatus {
        margin-left: 15px;
      }
    }
  }

  .itemWithdrawAdress {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;

    .txt1 {
      width: 100%;
      font-size: 12px;
      font-weight: 500;
      color: var(--color-black4);
    }

    .divder {
      width: 80%;
      height: 1px;
      background: var(--color-blueLi6);
      margin: auto;
    }

    .left .t1::after {
      display: none;
      content: "";
    }

    .txtAdress {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 4px;

      .copy {
        width: 15px;
        height: 15px;
      }
    }

    .btnsOp {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;

      .boxStatus {
        margin: 0;
        min-width: 40px;
      }
    }
  }

}

.btnShowMore {
  margin-top: 16px;
  width: 100%;
  background: var(--color-blue3);
  border: none;
  padding: 15px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-Onlywhite);

  span {
    font-size: 14px;
    color: var(--color-Onlywhite);
    font-weight: 400;
    display: flex;

    &::after {
      content: '';
      background: url('../svg/arrowDown.svg') no-repeat center / contain;
      width: 24px;
      height: 24px;
      margin-right: 5px;
    }
  }

  &.voucherBtn {
    padding: 10px;
    color: var(--color-Onlywhite);
  }
}

.profileUser {
  justify-content: flex-start;
  gap: 10px;

  .mainInput input,
  .mainInput textarea {
    direction: rtl;
    color: var(--color-black4);
  }

  .mainInput div span {
    border: none;
    border-left: 1px solid var(--color-blueLi3);
  }

  .mainEditInput {
    .topTxt {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      margin-bottom: 4px;
    }

    .status {
      font-size: 12px;
      font-weight: 400;
      padding: 2px 6px;
      color: var(--color-Onlywhite);
      background: var(--color-orange2);
      border-radius: 8px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;

      &.active {
        color: var(--color-green4);
        background: var(--color-greenLi3);
      }
    }
  }

  .txtBox {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    margin: 50px 0 20px 0;

    .t1 {
      font-size: 13px;
      font-weight: 600;
      color: var(--color-black4);
    }

    .t2 {
      font-size: 12px;
      font-weight: 400;
      color: var(--color-gray);
    }
  }
}

.yourBalanceNew {
  .txtBox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: auto;
    gap: 5px;

    .t1 {
      position: relative;
      top: 2px;
    }

    i {
      flex: unset;
    }
  }
}















/* ///////////// Dialog Style Public */

.titleCloseDialog {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px;
  direction: rtl;
  background-color: var(--color-white);

  >h3 {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    color: black;
  }
}

.nameCoinOrder {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 0;
}

/* ///////////dialog AddPhone */
.contentDetailDialog {
  display: flex;
  flex-direction: column;
  gap: 16px;
  direction: rtl;
  width: 400px;


  .textInfoDialog {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
  }

  .textInfoDialog span {
    padding: 10px;
    align-self: flex-start;
    position: relative;
    top: 2px;
    background-color: var(--color-orange);
  }

  .textInfoDialog h6 {
    font-size: 12px;
    color: var(--color-gray10);
    font-weight: 400;
  }

  button {
    width: 100%;
    color: var(--color-white);
    background-color: var(--color-blue3);
    font-size: 14px;
    margin-top: 16px;
    font-weight: 400;
    padding: 7px 0 9px 0;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    cursor: pointer;
    border: none;
  }

  .loadingCircular {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 37px;
    background-color: var(--color-blue3);
    margin-top: 16px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }

}

///////////////////////
.update-dialog {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  max-width: 400px;
  background: linear-gradient(145deg, var(--color-white), var(--color-white1));
  color: var(--color-black3);
  border-radius: 16px;
  box-shadow: 0 4px 6px var(--color-blackLi2);
  padding: 16px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 0;
  animation: fadeInUp 0.5s ease-out forwards;
  z-index: 1000;

  .txtBox {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: -20px;

    .tVer {
      font-size: 10px;
      margin-right: 3px;
    }
  }
}

.update-dialog .btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

/* افکت fade in برای نمایش */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate(-50%, 20px);
  }

  100% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

/* دکمه‌ها */
.update-dialog button {
  background: var(--color-green3);
  color: var(--color-white);
  border: none;
  border-radius: 12px;
  padding: 8px 10px;
  cursor: pointer;
  font-size: 13px;
  font-weight: bold;
  box-shadow: 0 4px 6px var(--color-blackLi2);
  transition: background 0.3s ease;
}

.update-dialog button:hover {
  background: var(--color-green2);
}

.update-dialog .close-btn {
  background: transparent;
  border: none;
  font-size: 16px;
  font-weight: bold;
  color: var(--color-gray4);
  cursor: pointer;
  padding: 8px;
  padding: 5px 10px;
  transition: color 0.3s ease;
}

.update-dialog .close-btn:hover {
  color: var(--color-gray);
}

/* حالت دارک مود */
@media (prefers-color-scheme: dark) {
  .update-dialog {
    background: linear-gradient(145deg, var(--color-black2), var(--color-black4));
    color: var(--color-white6);
    box-shadow: 0 4px 6px var(--color-blackLi3);
  }

  .update-dialog button {
    background: var(--color-blue6);
  }

  .update-dialog button:hover {
    background: var(--color-blue5);
  }

  .update-dialog .close-btn {
    color: var(--color-gray11);
  }

  .update-dialog .close-btn:hover {
    color: var(--color-white);
  }
}




/* //////////DialogWarning */

.MuiDialog-paper:has(.dialogWarningTitel),
.MuiDialog-paper:has(.titleCloseDialog) {
  background: var(--color-white) !important;
}

.dialogWarningTitel {
  padding: 16px !important;
  flex: unset;
  direction: rtl;
  background-color: var(--color-white);

  .titleCloseDialog {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0;
  }

  .textWarning {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;

    h4 {
      font-size: 15px;
      font-weight: 400;
      color: var(--color-black4);
    }
  }
}

.dialogWarning {
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  row-gap: 30px;
  direction: rtl;

  p {
    color: var(--color-black4);
    text-align: right;
    font-size: 15px;
    line-height: 29px;

  }

  .btns {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    width: 100%;
  }

  .btns button {
    flex: 1;
    color: var(--color-blue3);
    border: 1px solid var(--color-blue3);
    background-color: var(--color-Onlywhite);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    padding: 7px 0 9px 0;
    cursor: pointer;
  }

  .btns button:hover {
    background-color: var(--color-blueLi9);
  }

  .btns .btOk:hover {
    background-color: var(--color-blue4);
  }

  .btns .btOk {
    color: var(--color-Onlywhite);
    border: 1px solid var(--color-blue3);
    background-color: var(--color-blue3);
  }

  .loadingCircular {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--color-blue3);
    background-color: var(--color-blue3);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    height: 40px;
    width: 100%;
  }
}

.phishingWarning {
  width: 320px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  direction: rtl;

  .body {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 0px !important;
    direction: rtl;
    width: 100%;

    .topTxt {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;

      .t1 {
        font-size: 14px;
        color: var(--color-black4);
        font-weight: 400;
      }
    }


    .divider {
      width: 100%;
      background-color: var(--color-white10);
      margin-top: 21px;
      margin-bottom: 27px;
      height: 1px;
    }

    .warningText {
      font-size: 13px;
      font-weight: 400;
      color: var(--color-black4);
      margin-bottom: 27px;
    }

    .btnsTxt {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      width: 100%;
      direction: ltr;
      margin-top: 10px;

      .txtbox {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 5px;

        .t2 {
          font-size: 13px;
          color: var(--color-black4);
          font-weight: 400;
          direction: rtl;
        }
      }


      .finalCheckBtn {
        padding: 10px 15px;
        width: unset;
        margin-top: 0;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        background: var(--color-blue3);
        color: var(--color-white);
        cursor: pointer;
        transition: 0.3s ease;
        font-size: 13px;

        &:hover {
          box-shadow: none;
          background: var(--color-green4) !important;
        }

        &.btnCancel {
          background: var(--color-grayBlue);
          color: var(--color-white);

          &:hover {
            box-shadow: none;
            background: var(--color-red4) !important;
          }
        }
      }

    }

  }

}

//////////////////////////////////////tb_View
.tb_View {
  width: 100%;
  overflow: auto;

  table {
    display: table;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0px;

    thead {
      display: table-header-group;

      tr {
        th>h5 {
          display: inline-block;
          font-size: 13px;
          font-weight: 400;

          // background-color: var(--color-hoverNav);
          border-radius: 5px;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          -ms-border-radius: 5px;
          -o-border-radius: 5px;
          padding: 3px 5px;
        }
      }
    }

    tbody {
      display: table-row-group;

      tr {
        border-bottom: 1px solid var(--color-white16);

        &:hover {
          background: var(--color-white16);
          // cursor: pointer;
        }
      }

    }

    thead,
    tbody {
      tr {
        display: table-row;
        vertical-align: middle;

        th,
        td {
          display: table-cell;
          text-align: center;
          // padding: 10px;
          padding: 10px 5px;
          font-size: 13px;
          // padding-right: 15px;
          color: var(--color-gray6);
        }

      }
    }

  }
}

/////////////////////////////////////////////////////////////////////
///////////////////dashboard
.dashboard {
  // background: var(--color-white);
  // padding: 55px 63px;
  padding: 6px;

  .cartDetail {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 27px 17px 16px 17px;
    background: var(--color-white);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: 0px -3px 36.1px 0px var(--color-shadow);
    margin-bottom: 19px;

    .boxAuth {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 16px;
      width: 100%;

      .authChart {
        width: 92px;
        height: 92px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .boxText {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        gap: 12px;
        flex: 1 1;
        width: 100%;

        .t1 {
          font-size: 16px;
          font-weight: 400;
          color: var(--color-black4);
        }

        .t2 {
          font-size: 12px;
          font-weight: 400;
          color: var(--color-gray13);
        }

        .boxBtn {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          gap: 10px;
          flex: 1 1;
          width: 100%;

          .txts {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            width: 100%;

            .p1 {
              font-size: 12px;
              font-weight: 300;
              color: var(--color-gray14);

              &::before {
                content: "⚈";
                color: var(--color-green4);
                padding-left: 5px;
                position: relative;
                top: 3px;
              }
            }

            .p2 {
              font-size: 12px;
              font-weight: 300;
              color: var(--color-gray14);

              &::before {
                content: "⚈";
                color: var(--color-gray);
                padding-left: 5px;
                position: relative;
                top: 3px;
              }
            }
          }

          .startOrder {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;

            .btn {
              padding: 6px 5px;
              border: 1px solid var(--color-newBlue);
              background: var(--color-newBlue);
              color: var(--color-Onlywhite);
              font-size: 10px;
              font-weight: 400;
              border-radius: 4px;
              -webkit-border-radius: 4px;
              -moz-border-radius: 4px;
              -ms-border-radius: 4px;
              -o-border-radius: 4px;
              cursor: pointer;
              transition: 0.3s ease-in-out;
              -webkit-transition: 0.3s ease-in-out;
              -moz-transition: 0.3s ease-in-out;
              -ms-transition: 0.3s ease-in-out;
              -o-transition: 0.3s ease-in-out;

              &:hover {
                background: var(--color-green4);
                border: 1px solid var(--color-green4);
                color: var(--color-Onlywhite);
              }
            }

            .btn2 {
              padding: 6px 5px;
              border: 1px solid var(--color-blueToWhite);
              background: var(--color-white);
              color: var(--color-blueToWhite);
              font-size: 10px;
              font-weight: 400;
              border-radius: 4px;
              -webkit-border-radius: 4px;
              -moz-border-radius: 4px;
              -ms-border-radius: 4px;
              -o-border-radius: 4px;
              cursor: pointer;
              transition: 0.3s ease-in-out;
              -webkit-transition: 0.3s ease-in-out;
              -moz-transition: 0.3s ease-in-out;
              -ms-transition: 0.3s ease-in-out;
              -o-transition: 0.3s ease-in-out;

              &:hover {
                background: var(--color-green4);
                border: 1px solid var(--color-green4);
                color: var(--color-Onlywhite);
              }
            }
          }


          // .btn {
          //   display: flex;
          //   justify-content: center;
          //   align-items: center;
          //   gap: 5px;

          //   .icon-Arrow2 {
          //     transform: rotate(90deg);
          //     -webkit-transform: rotate(90deg);
          //     -moz-transform: rotate(90deg);
          //     -ms-transform: rotate(90deg);
          //     -o-transform: rotate(90deg);
          //     background: var(--color-blue8);
          //   }


          //   .p1 {
          //     font-size: 13px;
          //     font-weight: 400;
          //     color: var( --color-blue9);
          //     cursor: pointer;
          //   }

          // }
        }
      }
    }

    .divider {
      width: 100%;
      background-color: var(--color-white13);
      margin-top: 28px;
      margin-bottom: 20px;
      height: 1px;
    }

    .boxDetail {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      gap: 12px;
      width: 100%;

      .right {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 11px;

        .userIcon {
          width: 66px;
          height: 66px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;
          border: 1px solid var(--color-blueLi13);

          img {
            width: 55px;
            height: 55px;
            object-fit: cover;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
          }
        }

        .userName {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          gap: 5px;

          .t1 {
            font-weight: 400;
            font-size: 16px;
            text-align: right;
            color: var(--color-black4);

          }

          .t2 {
            font-weight: 400;
            font-size: 14px;
            text-align: right;
            color: var(--color-gray13);
          }
        }
      }

      .left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 32px;
        width: 100%;

        .item {
          display: flex;
          justify-content: flex-end;
          align-items: flex-start;
          flex-direction: column;
          gap: 5px;

          .t1 {
            font-weight: 400;
            font-size: 13px;
            text-align: right;
            color: var(--color-gray13);
          }

          .t2 {
            font-weight: 400;
            font-size: 15px;
            text-align: right;
            color: var(--color-black4);
          }
        }
      }

    }

    .boxSumOrders {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      margin-top: 15px;

      .t1 {
        font-size: 12px;
        color: var(--color-blueLi14);
        font-weight: 400;

        b {
          font-size: 12px;
          color: var(--color-black4);
          font-weight: 500;
        }
      }
    }

  }

  .cartWallet {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 23px;
    padding: 25px 16px;
    background: var(--color-white);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: 0px -3px 36.1px 0px var(--color-shadow);
    margin-bottom: 19px;

    .right {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 23px;
      flex: 0 auto;

      .detailWallet {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        gap: 23px;
        padding: 0 10px;

        .boxChart {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 20px;


          .chartWallet {
            width: 116px;
            height: 116px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .box1 {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 5px;

            .tx1 {
              font-weight: 400;
              font-size: 16px;
              text-align: right;
              color: var(--color-gray14);
            }

            .tx2 {
              font-weight: 400;
              font-size: 24px;
              color: var(--color-black4);
              line-height: normal;

              b {
                font-weight: 400;
                font-size: 14px;
                color: var(--color-gray13);

              }
            }

            .tx3 {
              font-weight: 400;
              font-size: 14px;
              color: var(--color-gray13);

              b {
                font-weight: 400;
                font-size: 16px;
                color: var(--color-gray13);
                direction: rtl;
              }
            }
          }

        }



        .box2 {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 28px;

          .item {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            gap: 7px;
          }

          .prog {
            width: 33px;
            height: 33px;
            position: relative;

            .icon-smallArrow {
              position: absolute;
              top: 50%;
              right: 50%;
              transform: translate(50%, -50%);
              -webkit-transform: translate(50%, -50%);
              -moz-transform: translate(50%, -50%);
              -ms-transform: translate(50%, -50%);
              -o-transform: translate(50%, -50%);
            }
          }

          .t1 {
            font-weight: 400;
            font-size: 12px;
            color: var(--color-gray13);
          }

          .t2 {
            font-weight: 400;
            font-size: 15px;
            color: var(--color-green4);

            &.t2tmn {
              color: var(--color-blue9);
            }

            b {
              font-weight: 300;
              font-size: 10px;
              color: var(--color-green4);
            }
          }
        }
      }

    }

    .left {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;
      gap: 40px;
      width: 100%;
      flex: 1 1;

      .balance {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        gap: 18px;
        width: 100%;
        background: var(--color-white15);
        padding: 18px 15px;
        border-radius: 11px;
        -webkit-border-radius: 11px;
        -moz-border-radius: 11px;
        -ms-border-radius: 11px;
        -o-border-radius: 11px;

        .item {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
          padding-right: 10px;

          .boxTxt {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            gap: 1px;

            .t1 {
              font-size: 13px;
              font-weight: 400;
              color: var(--color-gray13);
              position: relative;
              margin-bottom: 4px;

              &::before {
                content: "⚈";
                color: var(--color-green4);
                position: absolute;
                right: -17px;
                top: 0;
              }

              &.orangeBefore::before {
                color: var(--color-yellow1);
              }
            }

            .t2 {
              font-size: 15px;
              font-weight: 400;
              color: var(--color-black4);
            }

            .t3 {
              font-size: 12px;
              font-weight: 400;
              color: var(--color-gray13);
            }
          }


          .boxProg {
            width: 55px;
            height: 55px;
          }
        }

        .hordivider {
          height: 50px;
          width: 1px;
          background-color: var(--color-white13);
        }
      }

      .activityUser {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        gap: 21px;
        width: 100%;
        padding: 0 8px;

        .hordivider {
          height: 50px;
          width: 1px;
          background-color: var(--color-white13);
        }

        .item {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 12px;

          .icon {
            height: 25px;
            width: 30px;
          }

          .boxTxt {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            gap: 0px;

            .t1 {
              font-size: 14px;
              font-weight: 400;
              color: var(--color-black4);
            }

            .t2 {
              font-size: 13px;
              font-weight: 400;
              color: var(--color-gray13);
            }
          }
        }
      }
    }
  }

  .newsBox {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 13px;
    margin-bottom: 19px;

    .cardNewCoin {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      gap: 20px;
      padding: 28px 22px;
      background: var(--color-white);
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      box-shadow: 0px -3px 36.1px 0px var(--color-shadow);
      width: 100%;

      .boxTitle {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        .t1 {
          font-size: 15px;
          font-weight: 400;
          columns: var(--color-black4);
        }
      }

      .listNewCoin {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        gap: 8px;
        padding-left: 5px;
        height: 158px;
        overflow: auto;
        width: 100%;
        padding-bottom: 2px;

        &::-webkit-scrollbar-thumb {
          background: var(--color-green4);
        }

        .item {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 11px;
          border: 1px solid var(--color-blueLi13);
          border-radius: 10px;
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          -ms-border-radius: 10px;
          -o-border-radius: 10px;
          cursor: pointer;
          transition: 0.3s ease-in-out;
          -webkit-transition: 0.3s ease-in-out;
          -moz-transition: 0.3s ease-in-out;
          -ms-transition: 0.3s ease-in-out;
          -o-transition: 0.3s ease-in-out;

          &:hover {
            background: var(--color-hoverNav2);
          }


          .right {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 7px;

            .icon {
              width: 36px;
              height: 36px;
              object-fit: cover;
              border-radius: 50%;
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%;
              -ms-border-radius: 50%;
              -o-border-radius: 50%;
            }

            .boxText {
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              flex-direction: column;
              gap: 0;

              .nameCoin {
                font-size: 14px;
                font-weight: 400;
                color: var(--color-black4);
              }

              .textBox {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 4px;

                .t1 {
                  font-size: 13px;
                  font-weight: 400;
                  color: var(--color-gray14);
                }

                .volBox {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  gap: 2px;

                  .t2 {
                    font-size: 13px;
                    font-weight: 400;
                    color: var(--color-green4);
                  }
                }
              }
            }
          }

          .left {
            width: 111px;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }

    }

    .cartSilder {
      // flex: 1 1;
      width: 100%;
      // height: 240px;
      box-shadow: 0px -3px 36.1px 0px var(--color-shadow);
      padding: 0 3px;

      .itemImg {
        width: 100%;
        height: 244px;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        // padding: 5px;
      }
      .slick-slider,
      .slick-list {
        direction: ltr !important;
      }

      .slider-container {
        width: 100%;
        margin: auto;
        position: relative;
        border-radius: 10px;
        overflow: hidden;
      }

      .slide-image {
        width: 100%;
        height: 100%;
        max-height: 100%;
        object-fit: fill;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
      }

      .custom-dots {
        position: absolute;
        bottom: 17px;
        width: 100%;
        display: flex;
        justify-content: center;
        list-style: none;
        padding: 0;
        // margin-bottom: 10px;
      }

      .custom-dots li {
        margin: 0 3px;
        height: 10px;
      }

      .custom-dots li button {
        width: 20px;
        height: 3px;
        border-radius: 10px;
        background-color: #ddd;
        border: none;
        cursor: pointer;
        transition: background-color 0.3s ease, width 0.3s ease;
        -webkit-transition: background-color 0.3s ease, width 0.3s ease;
        -moz-transition: background-color 0.3s ease, width 0.3s ease;
        -ms-transition: background-color 0.3s ease, width 0.3s ease;
        -o-transition: background-color 0.3s ease, width 0.3s ease;
      }

      .custom-dots li.slick-active button {
        background-color: white;
      }

      .slick-dots li button:before {
        display: none;
      }

    }
  }

  .cardReadMore {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    gap: 28px;
    padding: 22px 24px;
    background: var(--color-white);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: 0px -3px 36.1px 0px var(--color-shadow);
    margin-bottom: 19px;

    .right {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;

      .t1 {
        font-size: 13px;
        font-weight: 400;
      }
    }

    .left {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 11px;

      .t1 {
        font-size: 12px;
        font-weight: 400;
      }
    }
  }

  .tableTra_NotBox {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 13px;
    margin-bottom: 19px;

    .cardTableTrans {
      width: 100%;
      min-height: 320px;
      max-height: 320px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      gap: 21px;
      padding: 31px 22px 27px 21px;
      background: var(--color-white);
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      box-shadow: 0px -3px 36.1px 0px var(--color-shadow);

      .boxTitle {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        .t1 {
          font-size: 14px;
          font-weight: 400;
          color: var(--color-black4);
        }

        .t2 {
          font-size: 13px;
          font-weight: 300;
          color: var(--color-blackLi4);
        }
      }

      .boxtabs {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 15px;
        width: 100%;

        .btn {
          display: flex;
          justify-content: center;
          align-items: center;
          min-width: 28%;
          max-width: 123px;
          gap: 3px;
          padding: 4px 5px;
          background: var(--color-white4);
          border: none;
          color: var(--color-black4);
          font-size: 12px;
          font-weight: 400;
          cursor: pointer;
          transition: 0.3s ease-in;
          -webkit-transition: 0.3s ease-in;
          -moz-transition: 0.3s ease-in;
          -ms-transition: 0.3s ease-in;
          -o-transition: 0.3s ease-in;
          // border: 1px solid var(--color-NO);

          span {
            transition: 0.3s ease-in;
            -webkit-transition: 0.3s ease-in;
            -moz-transition: 0.3s ease-in;
            -ms-transition: 0.3s ease-in;
            -o-transition: 0.3s ease-in;
          }

          &:hover {
            opacity: 0.7;
            // border: 1px solid var(--color-blue);
            // background: var(--color-blue);
            // color: var(--color-Onlywhite);

            // span {
            //   background: var(--color-Onlywhite);
            // }
          }

          &.active {
            background: var(--color-blue);
            color: var(--color-Onlywhite);

            span {
              background: var(--color-Onlywhite);
            }
          }
        }
      }

      .tb_listTrans {
        table {
          thead tr th {
            h5 {
              font-size: 13px;
              font-weight: 400;
              color: var(--color-black4);

            }
          }

          tbody tr td {
            font-size: 11px;
            font-weight: 400;
            color: var(--color-black4);
            text-align: center;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .nameCoin {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 5px;
            min-width: 70px;
            max-width: 70px;

            .iconCoin {
              width: 20px;
              height: 20px;
              object-fit: cover;
            }

            .t1 {
              font-size: 12px;
              font-weight: 400;
              color: var(--color-black4);
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              gap: 0;
              flex-direction: column;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;

              b {
                font-weight: 300;
                font-size: 12px;
                color: var(--color-blackLi4);
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                margin-top: -5px;
              }
            }
          }

          .status {

            &.confirm,
            &.complete {
              color: var(--color-green6);
            }

            &.process {
              color: var(--color-red3);
            }

            &.reject,
            &.cancel {
              color: var(--color-red4);
            }

            &.waiting,
            &.checking,
            &.pending {
              color: var(--color-yellow3);
            }
          }



          tbody tr td:nth-child(1),
          thead tr th:nth-child(1) {
            min-width: 70px;
            // max-width: 125px;
            text-align: right;
          }

          tbody tr td:nth-child(2),
          thead tr th:nth-child(2) {
            min-width: 90px;
            // max-width: 130px;
          }

          tbody tr td:nth-child(3),
          thead tr th:nth-child(3) {
            min-width: 90px;
            // max-width: 110px;
          }

          tbody tr td:nth-child(4),
          thead tr th:nth-child(4) {
            min-width: 55px;
            // max-width: 70px;
          }

        }
      }

      // .listTrans {
      //   display: flex;
      //   justify-content: flex-start;
      //   align-items: flex-start;
      //   flex-direction: column;
      //   gap: 14px;
      //   width: 100%;

      //   .itemHead {
      //     display: flex;
      //     justify-content: space-between;
      //     align-items: center;
      //     gap: 5px;
      //     padding-bottom: 12px;
      //     border-bottom: 1px solid var( --color-white14);
      //     width: 100%;

      //     p {
      //       font-size: 12px;
      //       font-weight: 400;
      //       color: var( --color-black4);
      //       text-align: center;
      //     }

      //   }

      //   .itemHead,
      //   .itemBody {
      //     .nameCoin {
      //       text-align: right;
      //       min-width: 150px;
      //       max-width: 150px;
      //     }

      //     .amount {
      //       min-width: 100px;
      //       max-width: 100px;
      //     }

      //     .date {
      //       min-width: 100px;
      //       max-width: 100px;
      //     }

      //     .status {
      //       min-width: 70px;
      //       max-width: 70px;
      //     }
      //   }

      //   .itemBody {
      //     display: flex;
      //     justify-content: space-between;
      //     align-items: center;
      //     gap: 5px;
      //     padding-bottom: 14px;
      //     border-bottom: 1px solid var( --color-white14);
      //     width: 100%;

      //     .nameCoin {
      //       display: flex;
      //       justify-content: flex-start;
      //       align-items: center;
      //       gap: 3px;

      //       .iconCoin {
      //         width: 20px;
      //         height: 20px;
      //         object-fit: cover;
      //       }

      //       .t1 {
      //         font-size: 12px;
      //         font-weight: 400;
      //         color: var( --color-black4);

      //         b {
      //           font-weight: 300;
      //           font-size: 12px;
      //           color: var(--color-blackLi4);
      //           white-space: nowrap;
      //           text-overflow: ellipsis;
      //         }
      //       }
      //     }

      //     >p {
      //       font-size: 12px;
      //       font-weight: 400;
      //       color: var( --color-black4);
      //       text-align: center;
      //     }
      //   }
      // }

    }

    .cardTableNotif {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      gap: 22px;
      padding: 27px 21px 27px 22px;
      background: var(--color-white);
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      box-shadow: 0px -3px 36.1px 0px var(--color-shadow);
      min-height: 385px;
      // max-height: 360px;

      .boxTitle {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        .t1 {
          font-size: 14px;
          font-weight: 400;
          color: var(--color-black4);
        }

        .t2 {
          font-size: 13px;
          font-weight: 300;
          color: var(--color-blackLi4);
        }
      }


      .boxtabs {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 15px;
        width: 100%;

        .btn {
          display: flex;
          justify-content: center;
          align-items: center;
          min-width: 28%;
          max-width: 123px;
          gap: 3px;
          padding: 4px 5px;
          background: var(--color-white4);
          border: none;
          color: var(--color-black4);
          font-size: 12px;
          font-weight: 400;
          cursor: pointer;
          transition: 0.3s ease-in;
          -webkit-transition: 0.3s ease-in;
          -moz-transition: 0.3s ease-in;
          -ms-transition: 0.3s ease-in;
          -o-transition: 0.3s ease-in;
          // border: 1px solid var(--color-NO);

          span {
            transition: 0.3s ease-in;
            -webkit-transition: 0.3s ease-in;
            -moz-transition: 0.3s ease-in;
            -ms-transition: 0.3s ease-in;
            -o-transition: 0.3s ease-in;
          }

          &:hover {
            opacity: 0.7;
            // border: 1px solid var(--color-blue);
            // background: var(--color-blue);
            // color: var(--color-Onlywhite);

            // span {
            //   background: var(--color-Onlywhite);
            // }
          }

          &.active {
            background: var(--color-blue);
            color: var(--color-Onlywhite);

            span {
              background: var(--color-Onlywhite);
            }
          }
        }
      }

      .listNotif {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        flex-direction: column;
        gap: 14px;
        height: 100%;

        &:has(.noItem) {
          margin: auto;
        }

        .item {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          width: 100%;
          gap: 11px;
          padding: 5px;
          cursor: pointer;
          transition: 0.3s ease-in-out;
          -webkit-transition: 0.3s ease-in-out;
          -moz-transition: 0.3s ease-in-out;
          -ms-transition: 0.3s ease-in-out;
          -o-transition: 0.3s ease-in-out;

          &:hover {
            background: var(--color-hoverNav2);
          }


          .iconLogo {
            width: 38px;
            height: 38px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: var(--color-white4);
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
          }

          .boxDetail {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            width: 100%;
            padding-bottom: 9px;
            border-bottom: 1px solid var(--color-white14);

            .txtBox {
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              gap: 4px;
              flex-direction: column;

              .t1 {
                font-size: 12px;
                font-weight: 400;
                color: var(--color-black4);
              }

              .t2 {
                font-size: 12px;
                font-weight: 300;
                color: var(--color-blackLi4);
              }
            }

            .icon-ArrowLeft {
              background: var(--color-blackLi4);
            }
          }
        }

        .emptyLoad {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          gap: 15px;
          width: 100%;

          p {
            font-size: 14px;
            font-weight: 400;
            color: var(--color-black4);
          }

          img {
            width: 75px;
            height: 70px;
          }
        }
      }
    }

  }

  .cardInvite {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 21px;
    padding: 31px 22px 27px 21px;
    background: var(--color-white);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: 0px -3px 36.1px 0px var(--color-shadow);
    // margin-bottom: 19px;

    .title {
      font-size: 15px;
      font-weight: 400;
      color: var(--color-black4);
    }

    .tb_Invited {
      table {
        thead tr th {
          text-align: right;
          padding-bottom: 23px;

          h5 {
            font-size: 13px;
            font-weight: 400;
            color: var(--color-black4);

          }
        }

        tbody tr td {
          text-align: right;
          padding: 15px 5px;

          h5 {
            font-size: 13px;
            font-weight: 400;
            color: var(--color-black4);

          }
        }
      }
    }
  }

  .cardChartChange {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    gap: 28px;
    background: var(--color-white);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: 0px -3px 36.1px 0px var(--color-shadow);
    margin-bottom: 19px;


    .topTxt {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      padding: 28px 22px;
      padding-bottom: 23px;
      border-bottom: 1px solid var(--color-white14);

      .t1 {
        font-size: 15px;
        font-weight: 400;
        color: var(--color-black4);
      }

      .selectDay {
        position: relative;
        display: flex;
        align-items: center;
        border: none;
        border-radius: 8px;
        padding: 5px 10px;
        cursor: pointer;
        gap: 3px;
        // width: 100px;

        .customSelect {
          width: 70px;
          border: none;
          background: transparent;
          font-size: 13px;
          text-align: center;
          color: var(--color-blueLi14);
          appearance: none;
          cursor: pointer;
          margin-left: -8px;

          option {
            background: var(--color-white);
          }
        }
      }

    }

    .contentBoxCoin {
      width: 100%;
      padding: 0 22px;

      .boxCoin {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        border-bottom: 1px solid var(--color-white14);
        width: 100%;
        overflow: auto;

        .item {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 7px;
          min-width: 192px;
          // width: fit-content;
          cursor: pointer;
          transition: 0.3s ease-in-out;
          -webkit-transition: 0.3s ease-in-out;
          -moz-transition: 0.3s ease-in-out;
          -ms-transition: 0.3s ease-in-out;
          -o-transition: 0.3s ease-in-out;
          padding: 6px 5px;

          &:hover {
            background: var(--color-whiteLi3);
          }

          .icon {
            width: 40px;
            height: 40px;
            object-fit: cover;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
          }

          .boxText {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            gap: 0;

            .nameCoin {
              font-size: 14px;
              font-weight: 500;
              color: var(--color-black4);
            }

            .textBox {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 4px;

              .t1 {
                font-size: 13px;
                font-weight: 400;
                color: var(--color-gray14);
              }

              .volBox {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 2px;

                .t2 {
                  font-size: 13px;
                  font-weight: 400;
                  color: var(--color-green4);
                }
              }
            }
          }
        }
      }
    }


    .styleChart {
      width: 100%;
      max-width: 100%;
      margin: auto;
      padding-right: 10px;
      padding-left: 10px;

      * {
        direction: ltr;
        // font-family: YekanBakh !important;
        font-family: YekanBakh, VazirEnNum, sans-serif !important;
        // font-family: IRANYekanXVF !important;
        font-size: 11px !important;
      }

      // .apexcharts-xaxis-label {
      //   transform: rotate(-45deg);
      //   -webkit-transform: rotate(-45deg);
      //   -moz-transform: rotate(-45deg);
      //   -ms-transform: rotate(-45deg);
      //   -o-transform: rotate(-45deg);
      // }
    }
  }

  .cartBtnsNav {
    flex: 1 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 22px 13px;
    background: var(--color-white);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: 0px -3px 36.1px 0px var(--color-shadow);
    margin-bottom: 19px;
    width: 100%;

    .itemBox1 {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      gap: 5px;
      padding: 5px 0px;

      &:first-child {
        border-bottom: 1px solid var(--color-div1);
      }
    }


    .item {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 11px;
      // padding: 8px 10px;
      transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      -ms-transition: 0.3s ease-in-out;
      -o-transition: 0.3s ease-in-out;
      max-width: calc(25% - 13px);
      min-width: calc(25% - 13px);

      &:hover {
        background: var(--color-hoverNav2);
      }

      .t1 {
        color: var(--color-txt5);
        font-size: 11px;
        font-weight: 400;
        text-align: center;
      }

      .cir1 {
        width: 54px;
        height: 54px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        background: var(--color-bgCir1);

        .cir2 {
          width: 44px;
          height: 44px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;
          background: var(--color-bgCir2);
        }
      }
    }

    .herDivider {
      width: 1px;
      max-width: 1px;
      min-width: 1px;
      height: 130px;
      min-height: 130px;
      max-height: 130px;
      background: var(--color-div1);
    }

  }

}

@media (min-width: 750px) {

  .menu,
  .top {
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
  }
}

@media (max-width: 550px) {

  .dashboard .cartWallet .left .activityUser .item:last-child,
  .dashboard .cartWallet .left .activityUser .hordivider:nth-child(4) {
    display: none;
  }
}

@media (max-width: 390px) {
  .dashboard .cartWallet .left {
    // .balance {
    //   flex-direction: column;

    //   .item {
    //     justify-content: space-between;
    //   }
    // }
    .balance {
      gap: 13px;

      .item {
        .boxProg {
          width: 48px;
          height: 48px;
        }

        .boxTxt .t1,
        .boxTxt .t2 {
          font-size: 13px;
        }

      }
    }

  }

  .dashboard .cartWallet .right .detailWallet .boxChart .box1 .tx2 {
    font-size: 18px;
  }

  .dashboard .tableTra_NotBox .cardTableTrans .boxtabs .btn {
    gap: 1px;
  }

  .dashboard .cartDetail .boxAuth .boxText .boxBtn .txts>p {
    font-size: 11px !important;
    white-space: nowrap;
  }
}


/////////////////////////////////////////////////////////////////////

@keyframes ring {
  0% {
    transform: rotate(0deg)
  }

  5% {
    transform: rotate(0deg)
  }

  15% {
    transform: rotate(0deg)
  }

  25% {
    transform: rotate(20deg)
  }

  35% {
    transform: rotate(-15deg)
  }

  45% {
    transform: rotate(10deg)
  }

  55% {
    transform: rotate(-5deg)
  }

  60% {
    transform: rotate(0deg)
  }

  100% {
    transform: rotate(0deg)
  }
}

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
  }

  1% {
    -webkit-transform: rotateZ(30deg);
  }

  3% {
    -webkit-transform: rotateZ(-28deg);
  }

  5% {
    -webkit-transform: rotateZ(34deg);
  }

  7% {
    -webkit-transform: rotateZ(-32deg);
  }

  9% {
    -webkit-transform: rotateZ(30deg);
  }

  11% {
    -webkit-transform: rotateZ(-28deg);
  }

  13% {
    -webkit-transform: rotateZ(26deg);
  }

  15% {
    -webkit-transform: rotateZ(-24deg);
  }

  17% {
    -webkit-transform: rotateZ(22deg);
  }

  19% {
    -webkit-transform: rotateZ(-20deg);
  }

  21% {
    -webkit-transform: rotateZ(18deg);
  }

  23% {
    -webkit-transform: rotateZ(-16deg);
  }

  25% {
    -webkit-transform: rotateZ(14deg);
  }

  27% {
    -webkit-transform: rotateZ(-12deg);
  }

  29% {
    -webkit-transform: rotateZ(10deg);
  }

  31% {
    -webkit-transform: rotateZ(-8deg);
  }

  33% {
    -webkit-transform: rotateZ(6deg);
  }

  35% {
    -webkit-transform: rotateZ(-4deg);
  }

  37% {
    -webkit-transform: rotateZ(2deg);
  }

  39% {
    -webkit-transform: rotateZ(-1deg);
  }

  41% {
    -webkit-transform: rotateZ(1deg);
  }

  43% {
    -webkit-transform: rotateZ(0);
  }

  100% {
    -webkit-transform: rotateZ(0);
  }
}

@-moz-keyframes ring {
  0% {
    -moz-transform: rotate(0);
  }

  1% {
    -moz-transform: rotate(30deg);
  }

  3% {
    -moz-transform: rotate(-28deg);
  }

  5% {
    -moz-transform: rotate(34deg);
  }

  7% {
    -moz-transform: rotate(-32deg);
  }

  9% {
    -moz-transform: rotate(30deg);
  }

  11% {
    -moz-transform: rotate(-28deg);
  }

  13% {
    -moz-transform: rotate(26deg);
  }

  15% {
    -moz-transform: rotate(-24deg);
  }

  17% {
    -moz-transform: rotate(22deg);
  }

  19% {
    -moz-transform: rotate(-20deg);
  }

  21% {
    -moz-transform: rotate(18deg);
  }

  23% {
    -moz-transform: rotate(-16deg);
  }

  25% {
    -moz-transform: rotate(14deg);
  }

  27% {
    -moz-transform: rotate(-12deg);
  }

  29% {
    -moz-transform: rotate(10deg);
  }

  31% {
    -moz-transform: rotate(-8deg);
  }

  33% {
    -moz-transform: rotate(6deg);
  }

  35% {
    -moz-transform: rotate(-4deg);
  }

  37% {
    -moz-transform: rotate(2deg);
  }

  39% {
    -moz-transform: rotate(-1deg);
  }

  41% {
    -moz-transform: rotate(1deg);
  }

  43% {
    -moz-transform: rotate(0);
  }

  100% {
    -moz-transform: rotate(0);
  }
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }

  1% {
    transform: rotate(30deg);
  }

  3% {
    transform: rotate(-28deg);
  }

  5% {
    transform: rotate(34deg);
  }

  7% {
    transform: rotate(-32deg);
  }

  9% {
    transform: rotate(30deg);
  }

  11% {
    transform: rotate(-28deg);
  }

  13% {
    transform: rotate(26deg);
  }

  15% {
    transform: rotate(-24deg);
  }

  17% {
    transform: rotate(22deg);
  }

  19% {
    transform: rotate(-20deg);
  }

  21% {
    transform: rotate(18deg);
  }

  23% {
    transform: rotate(-16deg);
  }

  25% {
    transform: rotate(14deg);
  }

  27% {
    transform: rotate(-12deg);
  }

  29% {
    transform: rotate(10deg);
  }

  31% {
    transform: rotate(-8deg);
  }

  33% {
    transform: rotate(6deg);
  }

  35% {
    transform: rotate(-4deg);
  }

  37% {
    transform: rotate(2deg);
  }

  39% {
    transform: rotate(-1deg);
  }

  41% {
    transform: rotate(1deg);
  }

  43% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(0);
  }
}