.icon-quoteSquare {
  display: flex;
  mask-image: url(../../../public/svg/quoteSquare.svg);
  background-color: var( --color-black4);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-arrowCircle {
  display: flex;
  mask-image: url(../../../public/svg/arrowCircle.svg);
  background-color: var( --color-black4);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 16px;
  height: 16px;
  padding: 6px;
}

.icon-deposit1 {
  display: flex;
  mask-image: url(../../../public/svg/deposit1.svg);
  background-color: var( --color-black4);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}
.icon-witdraw1 {
  display: flex;
  mask-image: url(../../../public/svg/witdraw1.svg);
  background-color: var( --color-black4);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-IconRepeat {
  display: flex;
  mask-image: url(../../../public/svg/m-IconRepeat.svg);
  background-color: var( --color-black4);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-logoGray {
  display: flex;
  mask-image: url(../../../public/svg/logoGray.svg);
  background-color: var(--color-graylogo);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 18.5px;
  height: 16.5px;
  padding: 6px;
}

.icon-selectIcon {
  display: flex;
  mask-image: url(../../../public/svg/selectIcon.svg);
  background-color: var(--color-graylogo);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 8.5px;
  height: 16.5px;
  padding: 6px;
}

.icon-ArrowLeft {
  display: flex;
  mask-image: url(../../../public/svg/m-ArrowLeft.svg);
  background-color: var( --color-black4);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 22px;
  height: 22px;
  padding: 6px;
}

.icon-treeDot {
  display: flex;
  mask-image: url(../../../public/svg/treeDot.svg);
  background-color: var(--color-gray13);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}
.icon-upVolume {
  display: flex;
  mask-image: url(../../../public/svg/upVolume.svg);
  background-color: var(--color-green4);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 14px;
  height: 8px;
  padding: 6px;
}
.icon-downVolume {
  display: flex;
  mask-image: url(../../../public/svg/downVolume.svg);
  background-color: var(--color-red4);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 14px;
  height: 8px;
  padding: 6px;
}

.icon-smallArrow {
  display: flex;
  mask-image: url(../../../public/svg/smallArrow.svg);
  background-color: var(--color-newBlue);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 5px;
  height: 6px;
  padding: 6px;
}
.icon-Arrow2 {
  display: flex;
  mask-image: url(../../../public/svg/Arrow.2.svg);
  background-color: var( --color-black4);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}



.icon-d_order {
  display: flex;
  mask-image: url(../../../public/svg/d_order.svg);
  background-color: var( --color-blueDark);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 32px;
  height: 32px;
  padding: 6px;
}


.icon-d_pro {
  display: flex;
  mask-image: url(../../../public/svg/d_pro.svg);
  background-color: var( --color-blueDark);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 32px;
  height: 32px;
  padding: 6px;
}

.icon-d_fast {
  display: flex;
  mask-image: url(../../../public/svg/d_fast.svg);
  background-color: var( --color-blueDark);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 32px;
  height: 32px;
  padding: 6px;
}

.icon-d_depo {
  display: flex;
  mask-image: url(../../../public/svg/d_depo.svg);
  background-color: var( --color-blueDark);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 32px;
  height: 32px;
  padding: 6px;
}

.icon-d_withdraw {
  display: flex;
  mask-image: url(../../../public/svg/d_withdraw.svg);
  background-color: var( --color-blueDark);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 32px;
  height: 32px;
  padding: 6px;
}
.icon-d_robat {
  display: flex;
  mask-image: url(../../../public/svg/d_robat.svg);
  background-color: var( --color-blueDark);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 32px;
  height: 32px;
  padding: 6px;
}
.icon-d_change {
  display: flex;
  mask-image: url(../../../public/svg/d_change.svg);
  background-color: var( --color-blueDark);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 32px;
  height: 32px;
  padding: 6px;
}

.icon-d_menu {
  display: flex;
  mask-image: url(../../../public/svg/d_menu.svg);
  background-color: var( --color-blueDark);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 32px;
  height: 32px;
  padding: 6px;
}


.icon-d_security {
  display: flex;
  mask-image: url(../../../public/svg/d_security.svg);
  background-color: var( --color-blueDark);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 32px;
  height: 32px;
  padding: 6px;
}

.icon-d_wallet {
  display: flex;
  mask-image: url(../../../public/svg/d_wallet.svg);
  background-color: var( --color-blueDark);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 32px;
  height: 32px;
  padding: 6px;
}