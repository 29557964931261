@import url(./colorTheme.css);

* {
  font-style: normal;
  font-size: 13px;
  font-family: YekanBakh, VazirEnNum, sans-serif;
  /* font: normal 13px IRANYekanXVF; */
  line-height: 22px;
  outline: none;
  text-decoration: none;
  margin: 0;
  padding: 0;
  direction: rtl;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

html, body {
  direction: rtl;
  text-align: right;
}

body {
  background-color: var(--bg-main);
  padding-bottom: 95px;
  color: var(--color-black4);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px var(--color-white) inset !important;
}

input:-webkit-autofill{
  -webkit-text-fill-color: var(--color-black4) !important;
}


.app{
  max-width: 750px;
  margin: 0 auto;
}

.ltr {
  direction: ltr;
  text-align: left;
}

.textRight {
  text-align: right;
}

.textCenter {
  text-align: center;
}

.w100Per {
  width: 100%;
}

.w30 {
  width: 30px;
}

.w40 {
  width: 40px;
}

.w50 {
  width: 50px;
}
.w60 {
  width: 60px;
}

.w70 {
  width: 70px;
}

.w100 {
  width: 100px;
}

.w90 {
  width: 90px;
}

.w110 {
  width: 110px;
}

.w120 {
  width: 120px;
}

.f1 {
  flex: 1;
}

.colorWhite {
  color: var(--color-white) !important;
}

.colorGreen {
  color: var(--color-green4) !important;
}

.colorRed {
  color: var(--color-red4) !important;
}

.bgGreen {
  background: var(--color-green4) !important;
}

.bgRed {
  background: var(--color-red4) !important;
}

.bgBlue {
  background: var(--color-blue7) !important;
}

.colorBlue {
  color: var(--color-blue7) !important;
}

.colorBlueTowhite {
  color: var(--color-blueToWhite) !important;
}

.bgGray,
.bgLight {
  background: var(--color-gray5);
}

.bgYellow {
  background: var(--color-yellow1);
}

.colorLight {
  color: var(--color-gray5);
}


.container {
  width: calc(100% - 30px);
  min-width: 330px;
  margin: 0 auto;
}
.container2 {
  width: 100%;
  /* min-width: 350px; */
  margin: 0 auto;
}

.top {
  margin-top: 10px;
  background: var(--color-white);
  width: 100%;
}

.top .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 52px;
  gap: 10px;
}

.top .container h1 {
  flex: 1;
  text-align: center;
  color: var(--color-black4);
}

.rightArrow,
.noInput::after {
  content: '';
  display: flex;
  mask-image: url('../svg/arrow.svg');
  background-color: var(--color-black4);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 10px;
  height: 10px;
  padding: 6px;
}

.rightArrowPad {
  padding: 15px;
  background-size: 6px;
  margin-right: -10px;
  margin-left: -15px;
}

.noInput::after {
  opacity: 0.7;
  transform: rotate(90deg);
}

.menuIcon {
  display: flex;
  mask-image: url('../svg/menu.svg');
  background-color: var(--color-black4);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.bellIcon {
  display: flex;
  mask-image: url('../svg/bell.svg');
  background-color: var(--color-black4);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.noticeIcon {
  display: flex;
  mask-image: url('../svg/notice.svg');
  background-color: var(--color-black4);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.supportIcon {
  display: flex;
  mask-image: url('../svg/support.svg');
  background-color: var(--color-black4);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.sunIcon {
  display: flex;
  mask-image: url('../svg/m-sun.svg');
  background-color: var(--color-black4);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}


.alarmBox .txtAlarm {
  background: var(--color-red2);
  color: var(--color-Onlywhite);
  border-radius: 100px;
  display: block;
  float: right;
  margin: -4px -4px 0 0;
  width: 20px;
  height: 20px;
  font-size: 10px;
  direction: ltr;
  padding-top: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
}

.alarmBox_0 .txtAlarm {
  background: var(--color-grayLi2) !important;
}

.info {
  display: flex;
  mask-image: url('../svg/info.svg');
  background-color: var(--color-black4);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 20px;
  height: 20px;
  padding: 6px;
}

.infoOrange {
  background: url('../svg/infoOrange.svg') no-repeat center / contain;
  width: 20px;
  height: 20px;
}

.alarms {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  flex-direction: column;
}

.emailAlarm {
  font-size: 12px;
  word-spacing: -1px;
  display: flex;
  background: var(--color-white);
  border: 1px solid var(--color-red4);
  border-radius: 8px;
  align-items: center;
}

.emailAlarm::before {
  content: '';
  background: url('../svg/email.svg') no-repeat center / contain;
  width: 24px;
  height: 24px;
  margin: 12px;
}

.identityAlarm::before {
  background-image: url('../svg/identity.svg');
}

.gAlarm::before {
  background-image: url('../svg/g.svg');
  transform: scale(1.14);
}

.icon-cardDown {
  display: flex;
  mask-image: url('../svg/cardDown.svg');
  background-color: var(--color-black4);
  mask-repeat: no-repeat;
  mask-position: center;
  padding: 20px;
  width: 60px;
  height: 60px;
  margin: 0 auto;
  margin-top: 20px;
  opacity: 0.8;
}

.whiteBg {
  background: var(--color-white);
}

.buyExpress {
  margin-top: 10px;
  padding: 15px 0;
}

.tabs {
  display: flex;
  gap: 5px;
}

.tabs >span {
  background: var(--color-white3);
  color: var(--color-black4);
  border-radius: 8px;
  padding: 3px 8px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  letter-spacing: -0.6px;
}

.tabs span.active {
  background:var(--color-blueLi8);
  color: var(--color-blueToWhite);
  position: relative;
}

.tabs span.active::after {
  content: '';
  display: block;
  position: absolute;
  width: 40%;
  height: 1px;
  background: var(--color-blue3);
  bottom: -1px;
  left: 30%;
}

.step {
  display: flex;
  margin-top: 20px;
  align-items: center;
  gap: 5px;
}

.step span {
  background: url('../svg/coin.svg') no-repeat center / contain;
  width: 22px;
  height: 22px;
}

.step2 span {
  background-image: url('../svg/trader.svg');
}

.step h3 i {
  color: var(--color-blue7);
}

.step div {
  height: 1px;
  flex: 1;
  background: linear-gradient(90deg, #00000000 0%, var(--color-blackLi2) 100%);
  margin-right: 10px;
}

.stepBelow {
  color: var(--color-gray6);
  font-size: 12px;
  margin: 4px 29px 0 0;
}

.coinList {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.coinList div,
.otherCoin {
  background: var(--color-white4);
  background: var(--color-white3);
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 15px 0;
}

.coinList div img {
  width: 34px;
  height: 34px;
  margin-bottom: 10px;
  border-radius: 100px;
  background:var(--color-white8);
}

.coinList div h3 {
  line-height: 12px;
}

.otherCoin {
  margin-top: 10px;
  padding: 8px 0;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.otherCoin .rightArrow {
  transform: rotate(180deg);
}

.coinList div.active {
  background: var(--color-blue10);
}

.coinList div h2 {
  white-space: nowrap;
}

.coinList div.active h3,
.coinList div.active h2,
.coinList div.active h2 span {
  color: var(--color-Onlywhite);
}

.buyingHelp {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 15px;
  font-size: 12px;
  color: var(--color-gray2);
  word-spacing: -1px;
}

.buyingHelp img {
  width: 26px;
  height: 26px;
}

.buyingHelp i {
  font-size: 12px;
}

.sellBuy {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.sellBuy span {
  flex: 1;
  background: var(--color-white);
  border: 1px solid var(--color-green4);
  color: var(--color-green4);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  gap: 5px;
  padding: 10px 0;
}

.sellBuy span:nth-of-type(2) {
  border-color: var(--color-red4);
  color: var(--color-red4);
}

.sellBuy span::before {
  content: '';
  width: 20px;
  height: 20px;
  background: var(--color-green4);
  mask-image: url('../svg/buy.svg');
  -webkit-mask-image: url('../svg/buy.svg');
  mask-size: contain;
  -webkit-mask-size: contain;
}

.sellBuy span:nth-of-type(2)::before {
  background: var(--color-red4);
  transform: rotate(180deg);
}

.sellBuy span.active {
  background: var(--color-green4);
  color: var(--color-Onlywhite);
}

.sellBuy span:nth-of-type(2).active {
  background: var(--color-red4);
}

.sellBuy span.active::before {
  background: var(--color-Onlywhite);
}

.formFast {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.payed {
  border: 1px solid var(--color-blueLi3);
  border: 1px solid var(--color-blueLi1);
  border-radius: 8px;
  display: flex;
  padding: 8px;
  gap: 11px;
  width: 100%;
}

.payed img {
  width: 30px;
  height: 30px;
  border-radius: 100px;
  background:var(--color-white8);
}

.payed input {
  flex: 1;
  background: none;
  border: none;
  font-size: 12px;
  width: 100%;
  color: var(--color-black4);
}

.convert {
  background: url('../svg/convert.svg') no-repeat center / contain;
  width: 40px;
  height: 40px;
  margin: 5px 0;
}

.yourBalance {
  display: flex;
  width: 100%;
  gap: 5px;
}

.yourBalance div {
  flex: 1;
  background:var(--color-greenLi1);
  border-radius: 8px;
  color:var(--color-green);
  width: 100%;
  text-align: center;
  padding: 8px 0;
}

.yourBalance i {
  display: inline-block;
  direction: ltr;
  position: relative;
  top: 1px;
  color: inherit;
}

.yourBalanceMarket {
  margin-top: 10px;
}

.yourBalanceMarket div {
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
}

.yourBalanceMarket i {
  flex: 1;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 5px;
}

.yourBalanceMarket i::before {
  content: '';
  background: url('../svg/addBalance.svg') no-repeat center / contain;
  width: 18px;
  height: 18px;
  margin-top: -2px;
}

.agreePay {
  background: var(--color-blue3);
  color: var(--color-Onlywhite);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 10px 0;
  width: 100%;
  gap: 10px;
  margin-top: 10px;
  word-spacing: -1.5px;
}

.agreePay i {
  background: var(--color-Onlywhite);
  padding: 3px 15px;
  color: var(--color-blue3);
  border-radius: 5px;
}

.runRange {
  width: 100%;
  margin-top: 15px;
}

.runRange>div {
  width: 100%;
  direction: ltr;
  background: var(--color-white6);
}

.runRange span {
  display: block;
  height: 16px;
  background: linear-gradient(-90deg, #00000000 0%, var(--color-blueLi11) 100%);
  border-left: 1px solid var(--color-blue7);
  direction: ltr;
  margin-top: -8px;
}

.runRange section {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.runRange section i {
  font-size: 12px;
}

.MuiSkeleton-root {
  background: var(--color-Skeleton) !important;
}

.loadingItem {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.noItem {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  text-align: center;
  font-size: 12px;
  color:var(--color-black4);
}

.noItem::before {
  margin: 0 auto;
  content: '';
  background: url('../svg/noItem.svg') no-repeat center / contain;
  width: 60px;
  height: 60px;
}

.menu {
  background: var(--color-white);
  border: 1px solid var(--color-white1);
  display: flex;
  justify-content: space-between;
  padding: 12px 25px 10px;
  position: fixed;
  bottom: 10px;
  left: 0px;
  right: 0px;
  width: calc(100%);
  box-shadow: 0px 20px 0 var(--color-white), 0px 20px 0 var(--color-white);
  max-width: 750px;
  margin: 0 auto;
}


.menu div {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  gap: 3px;
}

.menu div:nth-of-type(1)::before {
  content: '';
  display: flex;
  mask-image: url('../svg/dashboard.svg');
  background-color: var(--color-black4);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 20px;
  height: 20px;
  padding: 6px;
}
.menu .myPriceIcon::before {
  content: '';
  display: flex;
  mask-image: url('../svg/myPrice.svg') !important;
  background-color: var(--color-black4);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 20px;
  height: 20px;
  padding: 6px;
}

.menu div:nth-of-type(2):before {
  content: '';
  display: flex;
  mask-image: url('../svg/market.svg');
  background-color: var(--color-black4);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 20px;
  height: 20px;
  padding: 6px;
}

.menu div:nth-of-type(3):before {
  content: '';
  width: 20px;
  height: 20px;
  background: var(--color-blue7) url('../svg/trade.svg') no-repeat center / 60%;
  border-radius: 100px;
  transform: scale(2.5) translate(0, -8px);
  box-shadow: 0 4px 10px var(--color-blueLi12);
}

.menu div:nth-of-type(4):before {
  content: '';
  display: flex;
  mask-image: url('../svg/coins.svg');
  background-color: var(--color-black4);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 20px;
  height: 20px;
  padding: 6px;
}

.menu div:nth-of-type(5):before {
  content: '';
  display: flex;
  mask-image: url('../svg/wallet.svg');
  background-color: var(--color-black4);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 20px;
  height: 20px;
  padding: 6px;
}

.MuiCircularProgress-circle {
  color: var(--color-blue7);
}

.MuiAlert-root {
  opacity: 0.93;
}

body .MuiBackdrop-root {
  background: var(--color-whiteLi1);
  backdrop-filter: blur(1px);
}

.popup {
  height: 100%;
  background: var(--color-white);
}

.registerForm {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 20px;
  overflow-y: scroll;
}

.colorForm {
  background: var(--bg-main);
  justify-content: start;
}

.registerFormStart {
  justify-content: start;
}

.registerFormBottom {
  padding-bottom: 10px;
}

.logo {
  background: var(--logo) no-repeat center / contain;
  width: 160px;
  height: 160px;
  margin: 40px 0 0;


  /* display: flex;
  mask-image: url('../svg/logo.svg');
  background-color: var(--color-black4);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 160px;
  height: 160px;
  margin: 40px 0 0;
  padding: 60px; */
}

.inForm {
  width: 100%;
  padding: 0 40px;
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.inTitle {
  color: var(--color-blue7);
  font-weight: 600;
}

.inAlarm {
  color: var(--color-gray6);
  font-size: 12px;
}

.domain {
  background: var(--color-bgDomain);
  color: var(--color-black4);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
  padding: 8px 0;
  margin-top: 10px;
  font-size: 15px;
}

.domain::before {
  content: '';
  background: url('../svg/lock.svg') no-repeat center / contain;
  width: 20px;
  height: 20px;
  margin-right: 4px;
}

.domain i {
  color: var(--color-green4);
  direction: ltr;
  font-size: inherit;
}

.mainInput,
.noInput {
  margin-top: 10px;
}

.mainInput p {
  font-size: 12px;
  padding: 0 2px 1px 0;
  color: var(--color-black4);
}

.mainInput>div,
.noInput {
  border: 1px solid var(--color-blueLi2);
  border-radius: 8px;
  display: flex;
  flex-direction: row-reverse;
  padding: 13px 0;
}

.noInput {
  padding: 13px 15px 13px 13px;
  direction: ltr;
  color: var(--color-gray12);
  justify-content: space-between;
  align-items: center;
  gap: 7px;
}

.noInput img {
  width: 22px;
  height: 22px;
}

.noInput h3 {
  color: var(--color-black1);
  flex: 1;
}

.mainInput>div >span {
  border-right: 1px solid var(--color-blueLi3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainInput>div>span::after {
  content: '';
  background: url('../svg/mobile.svg') no-repeat center / contain;
  width: 22px;
  height: 22px;
  margin: 0 13px;
}

.mainInput>div span.password::after {
  background-image: url('../svg/key.svg');
}

.mainInput>div span.name::after {
  background-image: url('../svg/name.svg');
}

.mainInput>div span.email::after {
  background-image: url('../svg/email.svg');
}

.mainInput>div span.email2::after {
  background-image: url('../svg/emailLogin.svg');
}

.mainInput>div span.family::after {
  background-image: url('../svg/family.svg');
}

.mainInput>div span.id::after {
  background-image: url('../svg/id.svg');
}

.mainInput>div span.address::after {
  background-image: url('../svg/address.svg');
}

.mainInput>div span.birthday::after {
  background-image: url('../svg/birthday.svg');
}

.mainInput>div span.userprofile::after {
  background-image: url('../svg/userprofile.svg');
}

.mainInput input,
.mainInput textarea {
  background: none;
  border: none;
  direction: ltr;
  padding: 0 15px;
  font-size: 14px;
  flex: 1;
  color: var(--color-black4);
}

.mainInput textarea {
  height: 100px;
  color: var(--color-black4);
}

.birthdate select {
  background: none;
  border: none;
  font-size: 12px;
  flex: 1;
  border-right: 1px solid var(--color-white6);
  margin-left: 12px;
  padding-right: 12px;
  color: var(--color-black4);
  /* background: var(--bg-main); */
}

.birthdate select:nth-last-of-type(1) {
  border-right: none;
}

.birthdate select option{
  color: var(--color-black4);
  background: var(--bg-main);
}

.mainInput input.rtl,
.mainInput textarea.rtl {
  direction: rtl;
}


.verifyCode {
  display: flex;
  gap: 5px;
  margin-top: 22px;
  flex-direction: row-reverse;
}

.verifyCode input {
  flex: 1;
  width: 100%;
  background: var(--color-white2);
  border: 1px solid var(--color-white2);
  border-radius: 8px;
  text-align: center;
  height: 50px;
  color: var(--color-black4);
}

.verifyCode input:focus {
  background: var(--color-white);
  border-color:var(--color-blueLi3);
}

.editNum {
  background: var(--color-blue3);
  mask-image: url('../svg/editenum.svg');
  -webkit-mask-image: url('../svg/editenum.svg');
  mask-size: contain;
  -webkit-mask-size: contain;
  width: 22px;
  height: 22px;
  margin-left: 15px;
}

.iconInfo {
  background:var(--color-orange);
  mask-image: url('../svg/iconInfo.svg');
  -webkit-mask-image: url('../svg/iconInfo.svg');
  mask-repeat: no-repeat;
  mask-size: contain;
  -webkit-mask-size: contain;
  width: 30px;
  height: 30px;
}

.eye {
  background: var(--color-gray6);
  mask-image: url('../svg/eye.svg');
  -webkit-mask-image: url('../svg/eye.svg');
  mask-size: contain;
  -webkit-mask-size: contain;
  width: 22px;
  height: 22px;
  margin-right: 15px;
}

.fastMarket {
  background: var(--color-white);
  mask-image: url('../svg/fastMarket.svg');
  -webkit-mask-image: url('../svg/fastMarket.svg');
  mask-size: contain;
  -webkit-mask-size: contain;
  width: 20px;
  height: 20px;
}

.advanceMarket {
  background: var(--color-white);
  mask-image: url('../svg/advanceMarket.svg');
  -webkit-mask-image: url('../svg/advanceMarket.svg');
  mask-size: contain;
  -webkit-mask-size: contain;
  width: 20px;
  height: 20px;
}

.eye.active {
  background: var(--color-blue7);
}

.eyeAsset {
  background: var(--color-black1);
  margin: 0;
}

.rules {
  display: flex;
  gap: 5px;
  margin-top: 15px;
  align-items: center;
  font-size: 13px;
  word-spacing: -1px;
  color: var(--color-black4);
}

.newRules {
  display: unset !important;
  word-spacing: 0px;
  color: var(--color-black4);
}

.rules span {
  background: var(--color-white);
  border: 1px solid var(--color-gray2);
  border-radius: 4px;
  width: 17px;
  height: 17px;
  margin-top: -2px;
}

.rules .txt1 {
  font-size: 13px;
  margin-right: 5px;
  color: var(--color-black4);
}

.rules i {
  color: var(--color-blueToWhite);
  font-size: inherit;
}

.largeButton {
  margin-top: 15px;
  background: var(--color-blue3);
  color: var(--color-Onlywhite);
  font-weight: 500;
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  padding: 15px 0;
}

.largeButtonGreen {
  background: var(--color-green4);
}

.haveAccount {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.haveAccount p {
  color: var(--color-blueToWhite);
}

.alertParent {
  z-index: 10000;
  position: fixed;
  width: calc(100% - 20px);
  left: 10px;
  top: 10px;
}

.MuiAlert-root {
  flex-direction: row-reverse;
  align-items: center;
}

.MuiAlert-action {
  padding: 0 !important;
}

.MuiAlert-message {
  width: 100%;
}

.MuiDialog-paper {
  background: none !important;
  margin: 0 !important;
  box-shadow: 0 0 25px var(--color-blackLi3) !important;
  border-radius: 10px !important;
}

.confirmTitle {
  font-size: 15px;
  font-weight: 500;
  color: var(--color-black4);
}

.rules span.active {
  background: var(--color-blue3);
  border-color: var(--color-blue3);
}

.changeMobile {
  display: flex;
  justify-content: space-between;
  margin-top: 22px;
}

.changeMobile p {
  color: var(--color-gray3);
  display: flex;
  align-items: center;
  gap: 4px;
}

.changeMobile p::before {
  content: '';
  display: flex;
  mask-image: url('../svg/edit.svg');
  background-color: var(--color-black4);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 20px;
  height: 20px;
  padding: 6px;
}

.allSteps {
  display: flex;
  gap: 10px;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-top: 25px;
}

.allSteps div {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 11px;
  flex: 1;
  gap: 10px;
  white-space: nowrap;
}

.allSteps div span {
  width: 50px;
  height: 50px;
  background: var(--color-white);
  border-radius: 10px;
  box-shadow: 0 0 20px var(--color-blackLi2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.allSteps b {
  height: 2px;
  border-radius: 100px;
  flex: 0.3;
  background: var(--color-gray6);
}

.allSteps b:nth-last-of-type(1) {
  display: none;
}

.allSteps div span::after {
  content: '';
  background: var(--color-gray6);
  mask-image: url('../svg/security.svg');
  -webkit-mask-image: url('../svg/security.svg');
  mask-size: contain;
  -webkit-mask-size: contain;
  width: 26px;
  height: 26px;
}

.allSteps div.active span {
  box-shadow: none;
  background:var(--color-blueLi7);
  position: relative;
}

.allSteps div.active {
  color: var(--color-green4);
}

.allSteps div.active span::after {
  background: var(--color-green4);
}

.allSteps div.active span::before {
  content: '';
  background: url('../svg/tick.svg') no-repeat center / contain;
  width: 14px;
  height: 14px;
  position: absolute;
  right: -2px;
  top: -2px;
}

.allSteps div.active+b {
  background: var(--color-green4);
}

.allSteps div:nth-of-type(2) span::after {
  mask-image: url('../svg/card.svg');
  -webkit-mask-image: url('../svg/card.svg');
}

.allSteps div:nth-of-type(3) span::after {
  mask-image: url('../svg/confirm.svg');
  -webkit-mask-image: url('../svg/confirm.svg');
}

.inData {
  background: var(--color-white);
  border-radius: 10px;
  padding: 5px 20px 20px;
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.inData2 {
  /* background: var(--color-white); */
  /* border-radius: 10px; */
  /* padding: 5px 20px 20px; */
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
  /* margin-top: 20px; */
}

.inDataCenter {
  justify-content: space-between;
}

.inDataNo {
  flex: 0;
}

.spacer {
  flex: 1;
}

.spacerFix {
  flex: 1 !important;
}

.rulesCenter {
  justify-content: center;
  text-align: center;
  color: var(--color-black4);
}

.rulesCenterNo {
  display: block;
}

.bankLogo {
  width: 60px;
  height: 60px;
  margin: 0 auto;
  margin-top: 20px;
  opacity: 0.8;
}

.confirmData {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.confirmData h2 {
  font-weight: 600;
  font-size: 15px;
  color: var(--color-black4);
}

.confirmData h3 {
  font-size: 12px;
  text-align: center;
  max-width: 240px;
  color: var(--color-gray3);
}

.confirmData span {
  background: url('../svg/done.svg') no-repeat center / contain;
  width: 100px;
  height: 100px;
  margin-bottom: 15px;
}

.cancelData span {
  background-image: url('../svg/cancel.svg');
}

.changer_true {
  flex-direction: column-reverse;
}

.items {
  height: 190px;
  overflow-y: scroll;
  margin-top: 14px;
  display: flex;
  justify-content: center;
}

.openOrder {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.openOrder .newItemWallet {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.openOrder>div,
.openOrder .newItemWallet .newItem {
  display: flex;
  align-items: center;
}

.openOrder>div>span .iconCoinPair,
.openOrder .newItemWallet .newItem>span .iconCoinPair {
  position: relative;
  margin-left: 5px;
  height: 24px;
}

.openOrder>div>span .iconCoinPair .iconCoin,
.openOrder .newItemWallet .newItem>span .iconCoinPair .iconCoin {
  position: absolute;
  top: 0px;
  right: 5px;
}

.openOrder>div>span .iconCoinPair .iconPair,
.openOrder .newItemWallet .newItem>span .iconCoinPair .iconPair {
  position: relative;
  top: 3px;
  right: -1px;
  width: 19px;
  height: 19px;

}

.openOrder>div:nth-of-type(1),
.openOrder .newItemWallet .newItem:nth-child() {
  margin-bottom: 5px;
  border: none;
}

.openOrder>div:nth-last-of-type(1),
.openOrder .newItemWallet .newItem:nth-last-child(1) {
  border: none;
}

.openOrder>div:nth-of-type(n+2)>span,
.openOrder .newItemWallet .newItem>span {
  gap: 5px;
  padding: 8px 0;
  color: var(--color-black4);
}

.openOrder>div img,
.openOrder .newItemWallet .newItem img {
  width: 21px;
  height: 21px;
  border-radius: 100px;
  background:var(--color-white8);
}



.boxStatus {
  color: var(--color-Onlywhite);
  border-radius: 5px;
  padding: 0 5px;
  font-size: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 54px;
}

.boxStatus.bgYellow {
  min-width: 38px;
}

.inlineFlex {
  display: flex;
  align-items: center;
}

.listOfCoin {
  width: 100%;
  padding: 0 20px;
  flex: 1;
}

.listOfCoin div {
  flex: 1;
  display: flex;
  border-bottom: 1px solid var(--color-white8);
  padding: 12px 0;
  gap: 10px;
  align-items: center;
}

.listOfCoin div:nth-last-of-type(1) {
  border: none;
}

.listOfCoin div img {
  width: 28px;
  height: 28px;
  border-radius: 100px;
  background:var(--color-white8);
}

.search,
.searchIn {
  border: 1px solid var(--color-white6);
  border-radius: 8px;
  width: calc(100% - 30px);
  margin: 15px;
  display: flex;
  align-items: center;
  padding: 12px;
  color: var(--color-black4);
  background: var(--color-white2);
}

.searchIn {
  width: 100%;
  margin: 12px 0 0;
}

.search input {
  background: none;
  border: none;
  font-size: 12px;
  padding: 0 5px;
  flex: 1;
  color: var(--color-black4);
}

.search::after {
  content: '';

  display: flex;
  mask-image: url('../svg/search.svg');
  background-color: var(--color-black4);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 20px;
  height: 20px;
  padding: 2px;
}

.listOfCoin div h2,
.listOfCoin div h2 span {
  font-weight: 500;
  line-height: 14px;
  color: var(--color-black4);
}

.listOfCoin div h3 {
  font-size: 10px;
  line-height: 12px;
  color: var(--color-black4);
}

.listOfCoin div section:nth-of-type(1) {
  flex: 1;
}

.listOfCoin div section:nth-of-type(2) {
  direction: ltr;
}

.listOfCoin div section:nth-of-type(2) p {
  direction: ltr;
  line-height: 14px;
  font-size: 12px;
}

.percent {
  width: 50px;
  display: block;
  text-align: center;
  direction: ltr;
  padding: 3px 0 1px;
  line-height: 16px;
}

.titleOfAsset {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.titleOfAsset span {
  flex: 1;
  display: flex;
  justify-content: end;
  align-items: center;
  font-size: 11px;
  gap: 15px;
}

.titleOfAsset span .rightArrow {
  transform: rotate(180deg);
}

.bodyOfAsset {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}

.bodyOfAsset h3 {
  font-size: 15px;
  font-family: YekanBakhOlny;
}

.lineAsset {
  height: 1px;
  background:var(--color-blueLi3);
  margin: 12px 0;
}

.bodyOfAsset .boxStatus {
  width: auto;
  padding: 4px 10px 2px;
}

.bgwhite {
  background: var(--color-white);
  color: var(--color-black4);
  outline: 1px solid var(--color-white6);
  outline-offset: -1px;
}

.settingWallet {
  display: flex;
}

.forceButton {
  display: flex;
  gap: 5px;
  margin-top: 20px;
}

.forceButton div {
  border-radius: 6px;
  background: var(--color-green4);
  color: var(--color-Onlywhite);
  text-align: center;
  padding: 8px 0;
  flex: 1;
}

.forceButton div:nth-of-type(2) {
  background: var(--color-red4);
}

.hider {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.searchIcon {
  background: var(--color-black3);
  mask-image: url('../svg/search.svg');
  -webkit-mask-image: url('../svg/search.svg');
  mask-size: contain;
  -webkit-mask-size: contain;
  display: block;
  width: 21px;
  height: 21px;
  mask-repeat: no-repeat;
}

.advSearchIcon {
  background: var(--color-black3);
  mask-image: url('../svg/advSearchIcon.svg');
  -webkit-mask-image: url('../svg/advSearchIcon.svg');
  mask-size: contain;
  -webkit-mask-size: contain;
  display: block;
  width: 22px;
  height: 22px;
}

.hider .rules {
  margin: 0;
  flex-direction: row-reverse;
}

.menu div.active::after {
  content: '';
  display: block;
  background: var(--color-blue7);
  width: 8px;
  height: 8px;
  border-radius: 100px;
  position: absolute;
  bottom: -4px;
}

.cancelOrder {
  background: url('../svg/cancel.svg') no-repeat center / contain;
  width: 22px;
  height: 22px;
}

.disFlex {
  display: flex;
}

.confirmTitleIn {
  margin-top: 15px;
}

.confirmTitleIn+.rules {
  margin-top: 5px;
}

.emailLogo {
  background: url('../svg/email.svg') no-repeat center / contain;
  width: 70px;
  height: 70px;
  margin: 0 auto;
  margin-top: 40px;
}

.gLogo {
  background-image: url('../images/authenticator.png');
}

.smsLogo {
  background-image: url('../svg/sms.svg');
}

.smsLogoNew {
  background-image: url('../svg/smsNew.svg');
}

.docNew {
  background-image: url('../svg/docNew.svg');
}

.apps {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.apps a {
  background: url('../svg/bazar.svg') no-repeat center / contain;
  width: 98px;
  height: 40px;
}

.apps a:nth-of-type(2) {
  background-image: url('../svg/gp.svg');
}

.apps a:nth-of-type(3) {
  background-image: url('../svg/as.svg');
}

.upQR {
  text-align: center;
  margin-top: 10px;
}

.stepDeposit i {
  background:var(--color-blueLi8ToBlue);
  border-radius: 4px;
  color: var(--color-blueToWhite);
  width: 22px;
  text-align: center;
  padding-top: 2px;
  margin-left: 5px;
}

.addCard {
  display: flex;
  gap: 7px;
  color: var(--color-blueToWhite);
  font-size: 12px;
  align-items: center;
}

.stepDeposit h3 {
  flex: 1;
  font-weight: 500;
  color: var(--color-black4);
}

.addCard::before {
  content: '';
  background: url('../svg/addCard.svg') no-repeat center / contain;
  width: 22px;
  height: 22px;
  margin-top: -2px;
}

.addCardAddress::before {
  background-image: url('../svg/addressBook.svg');
}

.addCardAddress2::before {
  background-image: none;
  content: '';
  display: flex;
  mask-image: url('../svg/mIcon_addressBook.svg');
  background-color: var(--color-blueToWhite);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 13px;
  height: 13px;
  padding: 6px;
}

.addCardNo::before {
  display: none;
}

.defValues {
  display: flex;
  gap: 5px;
  margin-top: 20px;
}

.defValues span {
  border-radius: 5px;
  background: var(--color-white4);
  color: var(--color-black4);
  flex: 1;
  text-align: center;
  font-size: 11px;
  padding: 4px 0 4px;
}

.tabsTop {
  margin-top: 10px;
}

.notesTitle {
  color: var(--color-red4);
  font-weight: 500;
  font-size: 14px;
  margin-top: 10px;
}

.notesTitle::after {
  display: block;
  content: '';
  width: 29px;
  height: 2px;
  border-radius: 100px;
  background: var(--color-red4);
}

.noteList {
  display: flex;
  flex-direction: column;
}

.noteList span {
  font-size: 12px;
  margin-top: 15px;
  color: var(--color-black4);
}

.noteList span::before {
  content: '';
  display: inline-block;
  background: var(--color-black1);
  width: 5px;
  height: 5px;
  border-radius: 100px;
  margin-left: 7px;
}

.noteList span i {
  font-size: inherit;
  color: var(--color-red4);
}

.listOfCards {
  padding: 4px 14px;
}

.listOfCards div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid var(--color-white8);
  padding: 15px 0;
}

.listOfCards div:nth-last-of-type(1) {
  border: none;
}

.listOfCards div h2 {
  flex: 1;
  text-align: left;
  color: var(--color-black4);
}

.listOfCards div img {
  width: 24px;
  height: 24px;
}

.popupCircle {
  border-radius: 20px 20px 0 0;
}

.listCashCode {
  padding-top: 10px;
}

.listCashCode section {
  border-bottom: 1px solid var(--color-white7);
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.listCashCode section:nth-last-of-type(1) {
  border-bottom: none;
  padding-bottom: 0;
}

.listCashCode section div {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
}

.listCashCode span {
  font-size: 12px;
  word-spacing: -1px;
  color: var(--color-black4);
}

.listCashCode span:nth-of-type(1) {
  flex: 1;
}

.listCashCode span:nth-of-type(2) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.listCashCode i,
.copy {

  display: flex;
  mask-image: url('../svg/copy.svg');
  background-color: var(--color-black4);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 15px;
  height: 15px;
  padding: 9px;
}

.listCashCode img {
  width: 20px;
  height: 20px;
}

.optionList {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.optionList span {
  border-bottom: 1px solid var(--color-white8);
  color: var(--color-black4);
  text-align: right;
  margin: 0 15px;
  padding: 10px;
}

.optionList span:nth-last-of-type(1) {
  border-bottom: none;
}

.addressShow {
  background: var(--color-white4);
  border-radius: 8px;
  margin-top: 20px;
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.addressShow img {
  width: 60px;
  height: 60px;
}

.addressShow b {
  letter-spacing: -1.5px;
  flex: 1;
  display: flex;
  direction: ltr;
  line-height: 16px;
  color: var(--color-black4);
}

.addressShow .copy {
  transform: scale(1.2);
}

.addressShow div {
  display: flex;
  flex-direction: column;
}

.addressShow div h4 {
  font-size: 12px;
  white-space: nowrap;
  display: flex;
  gap: 6px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  color: var(--color-black4);
}

.addressShow div section {
  display: flex;
  gap: 6px;
  margin-top: 10px;
  align-items: center;
}

.addressShow div h4::before {
  content: '';
  background: url('../svg/tickSquare.svg') no-repeat center / contain;
  width: 20px;
  height: 20px;
}

.listCashTop {
  border-top: 1px solid var(--color-white8);
  margin-top: 20px;
  padding-top: 0;
}

.menuList {
  padding: 0 20px 0;
  flex: 1;
  width: 100%;
}

.menuList div,
.menuList span {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 25px;
  color:var(--color-black4);
}

.menuList div:nth-of-type(-n+4)::after {
  content: '';
  background: var(--color-black3);
  mask-image: url('../svg/arrow.svg');
  -webkit-mask-image: url('../svg/arrow.svg');
  mask-size: cover;
  -webkit-mask-size: cover;
  width: 7px;
  height: 12px;
  transform: rotate(90deg);
  margin-right: auto;
  margin-left: 5px;
  margin-top: 2px;
}

.menuList div.mn_true {
  color: var(--color-blue7);
}

.menuList div.mn_true::after {
  background: var(--color-blue7) !important;
}

.menuList div::before {
  content: '';
  background: var(--color-black);
  mask-image: url('../svg/ticket.svg');
  -webkit-mask-image: url('../svg/ticket.svg');
  mask-size: cover;
  -webkit-mask-size: cover;
  width: 23px;
  height: 23px;
}

.menuList div.mn_true::before {
  background: var(--color-blue7);
}

.menuList div.cardIcon::before {
  mask-image: url('../svg/card.svg');
  -webkit-mask-image: url('../svg/card.svg');
}

.menuList div.settingIcon::before {
  mask-image: url('../svg/setting.svg');
  -webkit-mask-image: url('../svg/setting.svg');
}

.menuList div.myPrice::before {
  mask-image: url('../svg/myPrice.svg');
  -webkit-mask-image: url('../svg/myPrice.svg');
}

.menuList div.bellsIcon::before {
  mask-image: url('../svg/bellPrice.svg');
  -webkit-mask-image: url('../svg/bellPrice.svg');
}

.menuList div.giftIcon::before {
  mask-image: url('../svg/gift.svg');
  -webkit-mask-image: url('../svg/gift.svg');
}

.menuList div.bookIcon::before {
  mask-image: url('../svg/book.svg');
  -webkit-mask-image: url('../svg/book.svg');
}

.menuList div.outIcon::before {
  mask-image: url('../svg/logout.svg');
  -webkit-mask-image: url('../svg/logout.svg');
}

.menuList div.walletIcon::before {
  mask-image: url('../svg/wallet.svg');
  -webkit-mask-image: url('../svg/wallet.svg');
}

.menuList span {
  font-weight: normal;
  font-size: 13px;
  margin-top: 8px;
  padding-right: 36px;
  position: relative;
}

.menuList span::before {
  /* content: ''; */
  border-right: 2px solid var(--color-white6);
  border-bottom: 2px solid var(--color-white6);
  border-bottom-right-radius: 10px;
  width: 12px;
  height: 38px;
  position: absolute;
  right: 10px;
  bottom: 9px;
}

.menuList div+span::before {
  height: 14px;
}

.menuList div.menuTitleLevel {
  background: var(--color-yellow1);
  color: var(--color-Onlywhite);
  border-radius: 8px;
  padding: 10px 13px;
  font-weight: normal;
  margin-left: -10px;
  margin-right: -10px;
}

.menuList .menuTitleLevel::before {
  background: var(--color-Onlywhite);
  mask-image: url('../svg/crown.svg');
  -webkit-mask-image: url('../svg/crown.svg');
}

.currentLevel {
  color: var(--color-blue7);
  display: block;
  margin: 15px 15px 0;
  border-radius: 8px;
  background: var(--color-white3);
  width: calc(100% - 30px);
  display: flex;
  justify-content: space-between;
  padding: 10px 14px;
}

.currentLevel b {
  font-size: 15px;
  font-family: YekanBakhOlny;
}

.currentBalance {
  color:var(--color-green);
  background: var(--color-white2);
}

.menuFlex {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.menuReg {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  gap: 5px;
}

.menuReg section {
  background: var(--color-blue7);
  color: var(--color-Onlywhite);
  flex: 1;
  border-radius: 70px;
  text-align: center;
  padding: 7px 0 9px;
}

.itemsFull {
  height: auto;
  flex: 1;
}

.mainInput .noInput {
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 0;
}

.allTicket div {
  align-items: center;
}

.allTicket div i {
  display: block;
  font-size: 11px;
  color: var(--color-gray5);
  direction: ltr;
  line-height: 12px;
  margin: 0px 0 4px;
}

.allTicket .boxStatus {
  padding: 2px 9px 2px !important;
  margin: 5px 0;
}

.replies div {
  background: var(--color-white4);
  margin-top: 15px;
  border-radius: 7px;
  padding: 10px 15px;
}

.replies div h4 {
  font-weight: 500;
  user-select: text;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  color: var(--color-black4);
}

.replies div span {
  font-size: 12px;
  user-select: text ;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  color: var(--color-black4);
}

.replies div img {
  height: 30px;
  display: block;
  border-radius: 5px;
  margin: 2px 0 4px;
}

.replies div i {
  font-size: 12px;
  display: block;
  color: var(--color-gray5);
  direction: ltr;
  text-align: right;
}

.replies .reply_user {
  margin-left: 30px;
}

.replies .reply_operator {
  margin-right: 30px;
}

.replies .reply_operator h4 {
  color: var(--color-blue7);
}

.addNew {
  background: var(--color-yellow1);
  color: var(--color-Onlywhite);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  width: 180px;
  margin-top: 15px;
  padding: 9px 0;
}

.addNew::before {
  content: '';
  background: url('../svg/add.svg') no-repeat center / contain;
  width: 22px;
  height: 22px;
}

.cardList div img {
  border-radius: 0;
  background: none;
  width: 24px;
  height: 24px;
}

.cardList div span {
  display: flex;
  align-items: center;
}

.cardList div:nth-of-type(n+2) span {
  padding: 8px 0;
}

.cardList div span:nth-of-type(1) {
  width: 40px;
}

.cardList div span:nth-of-type(2) {
  flex: 1;
}

.switcher {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-top: 15px;
  color: var(--color-black4);
}

.switcher::before {
  content: '';
  background: url('../svg/email.svg') no-repeat center / contain;
  width: 24px;
  height: 24px;
}

.switcher span {
  content: '';
  width: 42px;
  height: 22px;
  background: var(--color-white6);
  border-radius: 100px;
  margin-right: auto;
  transition: all 0.3s;
}

.switcher.active span {
  background: var(--color-green4);
}

.switcher span::after {
  content: '';
  width: 18px;
  height: 18px;
  background: var(--color-white);
  margin: 2px;
  border-radius: 100px;
  float: right;
  box-shadow: 0 0 10px var(--color-blackLi2);
  float: left;
  transition: all 0.3s;
}

.switcher.active span::after {
  margin-left: 22px;
}

.switcherGa::before {
  background: url('../svg/ga.svg') no-repeat center / contain;
}

.switcherEye::before {
  background:none;
  content: '';
  display: flex;
  mask-image: url('../svg/eye.svg');
  background-color: var(--color-black4);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.switcherSound::before {
  background:none;
  content: '';
  display: flex;
  mask-image: url('../svg/sound.svg');
  background-color: var(--color-black4);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.uploadPicture {
  border-radius: 5px;
  border: 1px dashed var(--color-white6);
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 120px;
}

.uploadPicture i {
  color:var(--color-gray7);
  font-size: 11px;
  text-align: center;
}

.uploadPicture span {
  /* display: block;
  background: url('../svg/upload.svg') no-repeat center / contain;
  width: 22px;
  height: 22px; */

  display: block;
  mask-image: url('../svg/upload.svg');
  background-color: var(--color-black4);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 22px;
  height: 22px;
  padding: 6px;
}

.fileUploader {
  position: relative;
  overflow: hidden;
}

.fileUploader label {
  opacity: 0;
  max-width: 100%;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.fileUploader img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.9;
  background: var(--color-white);
}

.inMarket {
  display: flex;
  margin-top: 10px;
  gap: 15px;
}

.rightMarket .sellBuy {
  margin: 0;
  gap: 5px;
}

.rightMarket {
  flex: 0.57;
}

.leftMarket {
  flex: 0.43;
}

.rightMarket .sellBuy span::before {
  display: none;
}

.rightMarket .sellBuy span {
  padding: 6px 0;
}

.rightMarket .limitMarket {
  margin-top: 10px;
}

.limitMarket span {
  background:var(--color-blueLi8);
  border: none;
  color: var(--color-blue7) !important;
  font-size: 12px;
}

.rightMarket .payed {
  padding: 8px 12px;
}

.receive {
  display: flex;
  width: 100%;
  margin-top: 10px;
}

.receiveTop {
  border-top: 1px dashed var(--color-white6);
  padding-top: 15px;
}

.receive div {
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  font-size: 11px;
  border-left: 1px dashed var(--color-white6);
}

.receive div:nth-of-type(2) {
  border: none;
}

.receive div i {
  font-size: 12px;
}

.receive div:nth-of-type(2) i {
  direction: ltr;
}

.rightMarket .runRange {
  margin: 10px 0;
}

.headMarket {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.marketTitle {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  gap: 8px;
  position: relative;
}

.marketTitle img {
  width: 24px;
  height: 24px;
  position: relative;
  top: 5px;
  border-radius: 100px;
  background:var(--color-white8);
}

.marketTitle img:nth-of-type(2) {
  position: absolute;
  right: -10px;
  top: -2px;
  transform: scale(0.8);
}

.marketTitle h3 {
  font-weight: 500;
  font-size: 15px;
  margin-top: 3px;
}

.marketTitle h3 i {
  display: block;
  color: var(--color-gray4);
  font-size: 11px;
  line-height: 6px;
}

.marketTitle::before {
  /* content: ''; */
  background: url('../svg/arrow.svg') no-repeat center / contain;
  width: 10px;
  height: 10px;
  transform: rotate(-180deg);
}

.leftMarket {
  display: flex;
  flex-direction: column;
}

.depth {
  display: flex;
  flex-direction: column;
}

.depth span {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 4px 0;
  padding-left: 6px !important;
}

.depth:nth-of-type(n+2) span section {
  position: absolute;
  background: var(--color-red4);
  content: '';
  height: 100%;
  width: 60%;
  opacity: 0.1;
  transition: opacity 0.3s;
}

.depth:nth-of-type(3) span section {
  background: var(--color-green4);
  opacity: 0.1;
}

.depth span * {
  line-height: 13px;
}

.depthTitle {
  margin-bottom: 4px;
}

.depthTitle span * {
  font-size: 12px;
}

.leftMarket .depth:nth-of-type(2) span i {
  color: var(--color-red4);
}

.leftMarket .depth:nth-of-type(3) span i {
  color: var(--color-green4);
}

.leftMarket h5 {
  text-align: center;
  font-size: 15px;
  padding: 13px 0;
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
  flex: 1;
}

.leftMarket h5::after,
.priceDay section:nth-of-type(2) div i::after {
  content: '';
  mask-image: url('../svg/arrow.svg');
  -webkit-mask-image: url('../svg/arrow.svg');
  mask-size: cover;
  -webkit-mask-size: cover;
  width: 6px;
  height: 10px;
  transform: rotate(-90deg);
}

.leftMarket h5.colorGreen::after,
.priceDay section:nth-of-type(2) div i::after {
  background: var(--color-green4);
  transform: rotate(-90deg);
  margin-top: -2px;
}

.leftMarket h5.colorRed::after,
.priceDay section:nth-of-type(2) div i.colorRed::after {
  background: var(--color-red4);
  transform: rotate(90deg);
  margin-top: -1px;
}

.leftMarket h5.colorLight::after,
.priceDay section:nth-of-type(2) div i.colorLight::after {
  display: none;
}

.payed_true {
  background:var(--color-blueLi3);
  opacity: 0.5;
}

.closePop {
  padding: 16px;
  position: absolute;
  left: 14px;
  top: 14px;
  background: var(--color-blueLi10);
  mask-image: url('../svg/cancel.svg');
  -webkit-mask-image: url('../svg/cancel.svg');
  mask-size: 22px;
  -webkit-mask-size: 22px;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-position: center;
}

.closePopWhite {
  background: var(--color-Onlywhite);
}

.closeChart {
  width: 34px;
  height: 34px;
  position: absolute;
  right: 14px;
  top: 14px;
  background: var(--color-red2);
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.closeChart::after {
  content: '';
  width: 16px;
  height: 16px;
  background: var(--color-white);
  mask-image: url('../svg/cancel.svg');
  -webkit-mask-image: url('../svg/cancel.svg');
  mask-size: 22px;
  -webkit-mask-size: 22px;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-position: center;
}

.stepMargin {
  margin-top: 5px;
}

.searchPrice {
  margin: 0;
  width: 100%;
}

.listOfCoinprice {
  padding: 10px 0 0;
}

.listOfCoinprice div {
  padding: 8px 0;
}

.listOfCoinprice div h3 {
  line-height: 12px;
  margin-top: 0;
}

.voucherData {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.voucherData img {
  width: 13px;
  height: 13px;
}

.voucherData h2 {
  font-size: 15px;
}

.voucherData .listCashCode {
  width: 100%;
}

.voucherCodeInput {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 20px;
}

.voucherCodeInput input {
  border: 1px solid var(--color-blueLi3);
  border-radius: 8px;
  padding: 8px;
  width: 100%;
  text-align: center;
  font-size: 12px;
  direction: ltr;
  color: var(--color-black4);
  background: transparent
}

.priceList {
  padding-top: 15px;
}

.priceList .search,
.pairsDrawer .search {
  background: var(--color-white);
  border: none;
}

.priceDay {
  display: flex;
  gap: 8px;
  margin-top: 8px;
  flex-direction: column;
}

.priceDay>div {
  background: var(--color-white);
  border-radius: 8px;
  padding: 12px 16px;
}

.priceDay section {
  display: flex;
}

.priceDay div img {
  width: 22px;
  height: 22px;
  border-radius: 100px;
}

.priceDay section {
  display: flex;
  gap: 10px;
  align-items: center;
}

.priceDay section:nth-of-type(2) {
  padding-top: 9px;
  justify-content: end;
}

.priceDay section:nth-of-type(1) {
  border-bottom: 1px solid var(--color-white8);
  display: flex;
  padding-bottom: 9px;
}

.priceDay section:nth-of-type(1) h3 {
  flex: 1;
  color: var(--color-gray3);
}

.fav {
  padding: 11px;
  background:var(--color-blueLi1);
  mask-image: url('../svg/star.svg');
  -webkit-mask-image: url('../svg/star.svg');
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-size: 16px;
  -webkit-mask-size: 16px;
  mask-position: center;
  -webkit-mask-position: center;
}

.fav_true {
  background:var(--color-yellow);
}

.priceDay section:nth-of-type(2) div {
  display: flex;
  gap: 5px;
  align-items: center;
  flex-direction: row-reverse;
  direction: ltr;
  justify-content: left;
}

.priceDay section:nth-of-type(2) div:nth-of-type(2) {
  flex: 1;
}

.priceDay section:nth-of-type(2) div * {
  direction: ltr;
  text-align: left;
}

.priceDay section:nth-of-type(2) div i {
  display: flex;
  align-items: center;
  gap: 5px;
}

.profileMenu {
  width: 100%;
  background: var(--color-white);
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
}

.profileImage {
  display: flex;
  gap: 15px;
  width: 100%;
  padding: 15px 15px 0 0;
}

.profileImage img {
  width: 40px;
  height: 40px;
  background:var(--color-blueLi3);
  border-radius: 100px;
}

.profileImage section {
  display: flex;
  /* flex-direction: column; */
  justify-self: center;
  align-items: center;
}

.profileImage section p:nth-of-type(1) {
  margin-top: 1px;
  color:var(--color-black4);
}

.profileImage section p:nth-of-type(2) {
  font-size: 12px;
  color:var(--color-gray7);
  margin-top: -2px;
}

.menuList .bookIcon {
  border-top: 1px solid var(--color-white8);
  border-bottom: 1px solid var(--color-white8);
  padding: 18px 0;
}

.social {
  margin-top: 20px;
}

.social p {
  font-size: 12px;
  color:var(--color-black4);
}

.social section {
  display: flex;
  gap: 20px;
  margin-top: 15px;
}

.social section a {
  content: '';
  background: url('../svg/telegram.svg') no-repeat center / contain;
  width: 26px;
  height: 26px;
}

.social section a:nth-of-type(2) {
  background-image: url('../svg/instagram.svg');
}

.social section a:nth-of-type(3) {
  background-image: url('../svg/facebook.svg');
}

.social section a:nth-of-type(4) {
  background-image: url('../svg/whatsapp.svg');
}

.tabsPrice {
  display: flex;
  margin-top: 10px;
  gap: 10px;
}

.tabsPricePair,
.tabsPair {
  margin: 0 15px;
  width: calc(100% - 30px);
}

.tabsPair {
  display: flex;
  margin-top: 15px;
  margin-bottom: 15px;
}

.tabsPair span {
  flex: 1;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid transparent;
  gap: 5px;
  margin: 0 5px;
  padding: 0 5px 10px;
  position: relative;
  white-space: nowrap;
  color: var(--color-black4);
}

.tabsPair span::after {
  content: '';
  display: block;
  left: -5px;
  top: 5px;
  position: absolute;
  height: 14px;
  background: var(--color-white6);
  width: 1px;
}

.tabsPair span:nth-last-of-type(1):after {
  display: none;
}

.tabsPair span.active {
  color: var(--color-blueToWhite);
}

.tabsPair span.active {
  border-bottom-color: var(--color-blue3);
}

.tabsPrice span {
  flex: 1;
  text-align: center;
  background: var(--color-white);
  color: var(--color-black4);
  border-radius: 7px;
  padding: 8px 0;
}

.tabsPair span:nth-of-type(2)::before,
.tabsPair span.mytmn::before,
.tabsPair span:nth-of-type(3)::before {
  content: '';
  display: block;
  background: url('../images/tmn.png') no-repeat center / contain;
  width: 18px;
  height: 18px;
}

.tabsPair span:nth-of-type(3)::before {
  background-image: url('../images/usdt.png');
}

.tabsPrice span.active {
  color: var(--color-blueToWhite);
  background:var(--color-blueLi6);
}

.MuiDialog-paper .popup {
  width: 340px;
}

.fileUploaderTicket {
  border-radius: 10px;
  margin-top: 15px;
}

.viewCard div {
  background: var(--color-white);
  border-radius: 12px;
  display: flex;
  justify-content: left;
  position: relative;
  height: 200px;
}

.viewCard div>img {
  width: 100px;
  height: 100px;
  margin: 50px;
  opacity: 0.2;
  object-fit: contain;
}

.viewCard div span {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: justify;
  white-space: nowrap;
}

.viewCard div span img {
  width: 60px;
  height: 60px;
  object-fit: contain;
  margin-bottom: 20px;
}

.viewCard div span b {
  font-size: 19px;
  letter-spacing: 2.4px;
  text-align: center;
  color: var(--color-black4);
}

.viewCard div span i {
  font-size: 14px;
  letter-spacing: 2px;
  text-align: center;
  color: var(--color-black4);
}

.viewCard section {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  color: var(--color-black4);
}

.viewCard section * {
  font-size: 12px;
}

.pairsDrawer .search {
  margin: 15px 15px 10px;
  width: calc(100% - 30px);
}

.listOfPair {
  gap: 10px;
  display: flex;
  flex-direction: column;
  width: calc(100% - 30px);
}

.listOfPair>div {
  background: var(--color-white);
  border: none;
  border-radius: 8px;
  padding: 12px;
}

.tabsPairFull {
  overflow-x: auto;
  flex: none;
}

.tabsPairFull span::before {
  display: none !important;
}

.listOfPair div {
  width: 100%;
  display: flex;
  padding: 10px 15px;
  gap: 10px;
  align-items: center;
}

.listOfPair div img {
  width: 28px;
  height: 28px;
  border-radius: 100px;
  background:var(--color-white8);
}

.listOfPair div section:nth-of-type(1) {
  flex: 1;
}

.listOfPair div section:nth-of-type(2) {
  direction: ltr;
}

.listOfPair div section:nth-of-type(2) p {
  direction: ltr;
  margin-top: 6px;
  line-height: 13px;
  color: var(--color-black4);
}

.listOfPair div section:nth-of-type(2) i {
  margin-top: 3px;
}

.listOfPair div h2,
.listOfPair div h2 span {
  font-weight: 500;
  line-height: 14px;
  margin-top: 4px;
  color: var(--color-black4);
}

.listOfPair div h3 {
  margin-top: 2px;
  color: var(--color-gray3);
}

.mainInputAddress {
  display: flex;
  gap: 5px;
  border-top: 1px dashed var(--color-white6);
  padding-top: 15px;
  flex-wrap: wrap;
}

.mainInputAddress div {
  flex: 0 0 calc(50% - 2.5px);
}

.mainInputAddress div:nth-of-type(1) {
  flex: 0 0 100%;
}

.mainInputAddress div input {
  width: 100%;
  text-align: center;
  font-size: 11px;
  color: var(--color-black4);
}

.mainInputAddress h3,
.mainInputAddress .noInput {
  font-size: 11px;
}

.listOfAddress {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 15px;
  gap: 10px;
}

.listOfAddress .noItem {
  flex: 1;
}

.listOfAddress div {
  display: flex;
  background: var(--color-white3);
  border-radius: 100px;
  padding: 5px 12px;
}

.listOfAddress div span {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
}

.listOfAddress div span:nth-last-of-type(1) {
  flex: none;
}

.parentPairs {
  display: flex;
  align-items: center;
  width: 100%;
}

.parentPairs .rightArrowPad {
  margin-right: 0;
  padding-left: 20px;
  padding-right: 20px;
}

.memoShow {
  background:var(--color-blueLi4);
  margin-top: 15px;
}

.memoShow b {
  letter-spacing: 0;
}

.alertMemo {
  text-align: center;
  color: var(--color-red4);
  font-size: 12px;
  line-height: 17px;
  width: 280px;
  margin: 15px auto 0;
  animation: blink-animation 1s steps(5, start) infinite;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

.levelView {
  width: 100%;
  background: var(--color-white);
  box-shadow: 0 5px 15px var(--color-blackLi2) !important;
  border-radius: 9px;
  overflow: hidden;
  margin-top: 15px;
}

.levelView.hide {
  height: 47px;
}

.levelView h3 {
  background:var(--color-green);
  overflow: hidden;
  display: flex;
  color: var(--color-white);
  padding: 12px 17px;
  gap: 10px;
  align-items: center;
}

.levelView h3 i {
  width: 60px;
  color: var(--color-black4);
}

.levelView h3 p {
  color:var(--color-green);
  background: var(--color-Onlywhite);
  border-radius: 4px;
  font-size: 11px;
  padding: 1px 8px 0;
}

.levelView h3::after {
  content: '';
  width: 7px;
  height: 13px;
  background: var(--color-Onlywhite);
  mask-image: url('../svg/arrow.svg');
  -webkit-mask-image: url('../svg/arrow.svg');
  mask-size: contain;
  -webkit-mask-size: contain;
  transform: rotate(90deg);
  margin-right: auto;
}

.levelView>div {
  padding: 10px 0 20px;
  margin: 0 40px;
  border-bottom: 1px solid var(--color-white6);
}

.levelView>div:nth-of-type(3) {
  border-bottom: none;
}

.levelView>div h6 {
  font-weight: 500;
  color: var(--color-black4);
}

.levelView section {
  margin-top: 15px;
}

.levelView section span {
  color:var(--color-green);
  display: flex;
  font-size: 12px;
  gap: 10px;
  position: relative;
}

.levelView section article {
  background:var(--color-green);
  width: 1px;
  height: 20px;
  margin: 5px 9px 7px;
}

.levelView section span::before {
  content: '';
  display: block;
  border: 1px solid var(--color-green);
  border-radius: 100px;
  width: 18px;
  height: 18px;
}

.levelView section span::after {
  content: '';
  background:var(--color-green);
  mask-image: url('../svg/tick.svg');
  -webkit-mask-image: url('../svg/tick.svg');
  mask-size: cover;
  -webkit-mask-size: cover;
  width: 14px;
  height: 9px;
  position: absolute;
  margin: 6px 3px 0 0;
}

.levelView>div>div {
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.levelView>div>div span:nth-of-type(1) {
  content: '';
  background:var(--color-green);
  mask-image: url('../svg/tick.svg');
  -webkit-mask-image: url('../svg/tick.svg');
  mask-size: cover;
  -webkit-mask-size: cover;
  width: 14px;
  height: 9px;
}

.levelView>div>div span {
  font-size: 12px;
  color: var(--color-black4);
}

.levelView>div>div span:nth-of-type(2) {
  color: var(--color-gray2);
  flex: 1;
}

.largeButtonLevel {
  font-family: YekanBakhOlny;
  font-weight: 600;
  margin: 0 15px 15px;
}

.selectTrade {
  text-align: center;
}

.selectTrade h6 {
  text-transform: uppercase;
  padding: 30px 0 10px;
  font-size: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--color-Onlywhite);
}

.selectTrade h6 img {
  width: 26px;
  margin-top: -3px;
}

.selectTrade div {
  display: flex;
  padding: 15px;
  gap: 15px;
}

.selectTrade div .btnTrade {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex: 1;
  text-align: center;
  background: var(--color-blue7);
  border-radius: 8px;
  padding: 7px 0 8px;
  color: var(--color-Onlywhite);
}

.selectTrade div .btnTrade span {
  font-size: 12px;
  color: var(--color-Onlywhite);
}

.selectTrade div .btnTrade .advanceMarket,
.selectTrade div .btnTrade .fastMarket{
  background: var(--color-Onlywhite) !important;
}

.alarmsData {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 10px;
}

.alarmsData div {
  background: var(--color-white3);
  border-radius: 8px;
  position: relative;
  padding: 14px 20px 12px;
  color:var(--color-black4);
}

.alarmsData div.noSee {
  padding-top: 35px;
}

.alarmsData div i {
  display: block;
  color: var(--color-gray5);
  text-align: right;
}

.alarmsData div span {
  position: absolute;
  left: 9px;
  top: 9px;
  background: var(--color-yellow1);
  color: var(--color-white);
  font-size: 9px;
  line-height: 18px;
  border-radius: 100px;
  padding: 1px 8px 0;
}

.alarmsData div h6 {
  color: var(--color-blueToWhite);
}

.alarmsData div h6::before {
  content: '';
  width: 8px;
  height: 8px;
  background: var(--color-blue7);
  border-radius: 100px;
  display: inline-block;
  margin-left: 5px;
  position: relative;
  top: 1px;
}

.inTut {
  margin-top: 20px;
  background: var(--color-white);
  padding: 15px;
  border-radius: 8px;
}

.tutList {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.tutList span {
  width: calc(50% - 5px);
  border: 1px solid var(--color-blueLi5);
  border-radius: 7px;
  text-align: center;
  font-size: 12px;
  padding: 20px 0;
  color: var(--color-black4);
}

.tutList span:nth-last-of-type(1) {
  width: 100%;
}

.tutList span::before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 50px;
  height: 50px;
  background: url('../svg/t1.svg') no-repeat center / contain;
}

.tutList span:nth-of-type(2):before {
  background-image: url('../svg/t2.svg');
}

.tutList span:nth-of-type(3):before {
  background-image: url('../svg/t3.svg');
}

.tutList span:nth-of-type(4):before {
  background-image: url('../svg/t4.svg');
}

.tutList span:nth-of-type(5):before {
  background-image: url('../svg/t5.svg');
}

.tutList span:nth-of-type(6):before {
  background-image: url('../svg/t6.svg');
}

.tutList span:nth-of-type(7):before {
  background-image: url('../svg/t7.svg');
}

.inTut h5 {
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  margin-top: 15px;
  color: var(--color-black4);
}

.searchTut {
  background: var(--color-white4);
  border-radius: 8px;
  display: flex;
  overflow: hidden;
  align-items: center;
  margin: 20px 0;
}

.searchTut span {
  background: var(--color-blue3);
  color: var(--color-Onlywhite);
  align-self: stretch;
  display: flex;
  align-items: center;
  padding: 0 14px 1px;
  line-height: 12px;
  font-size: 12px;
}

.searchTut input {
  background: none;
  border: none;
  flex: 1;
  padding: 10px;
  color: var(--color-black4);
}

.searchTut i {
  margin-right: 13px;
  opacity: 0.4;
}

.pageList {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.pageList span {
  border-top: 1px solid var(--color-blueLi5);
  padding: 12px 0 2px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.pageList span::before {
  content: '';
  width: 22px;
  height: 22px;
  background: url('../svg/q1.svg') no-repeat center / contain;
}

.pageList span:nth-of-type(2n)::before {
  background-image: url('../svg/q2.svg');
}

.backToPage {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 20px;
  justify-content: center;
  font-weight: 500;
  color: var(--color-black4);
}

.backToPage::before {
  content: '';
  background: url('../svg/arrow.svg') no-repeat center / contain;
  width: 10px;
  height: 10px;
}

.popupTut img,
.popupTut figure {
  width: 100% !important;
  height: auto !important;
}

.popupTut {
  padding: 20px;
}

.popupTut h1 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}

.parentMemo {
  position: relative;
}

.alarmMemo {
  position: absolute;
  left: 0;
  top: 20px;
  background: var(--color-whiteLi2);
  padding: 0 30px;
  width: 100%;
  height: calc(100% - 20px);
  border-radius: 8px;
  display: flex;
  z-index: 900000;
  align-items: center;
  justify-content: center;
  color: var(--color-black1);
  text-align: center;
  flex-direction: column;
  backdrop-filter: blur(2px);
  gap: 15px;
}

.popupMemo {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  color: var(--color-black4);
}

.alarmMemo i,
.popupMemo i {
  background: var(--color-blue7);
  color: var(--color-Onlywhite);
  border-radius: 6px;
  padding: 6px 17px;
}

.alarmMemo::before,
.popupMemo::before {
  content: '';
  background: url('../svg/error.svg') no-repeat center / contain;
  width: 60px;
  height: 60px;
}

.viaFa {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.viaFa .symbolCoin {
  font-size: 11px;
  margin-left: 2px;
}

.upTabs {
  display: flex;
  justify-content: space-between;
}

.refresh {
  background: var(--color-blue3);
  color: var(--color-Onlywhite);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  font-size: 12px;
  padding: 0 12px;
  gap: 5px;
}

.refresh::before {
  content: '';
  background: url('../svg/refresh.svg') no-repeat center / contain;
  width: 14px;
  height: 14px;
}

.selectParent {
  position: relative;
}

.downSelect {
  background: var(--color-white);
  position: absolute;
  left: -1px;
  top: 59px;
  width: calc(100% + 2px);
  max-height: 300px;
  overflow: auto;
  z-index: 100000;
  border-radius: 10px;
  box-shadow: 0 0 15px var(--color-blackLi3) !important;
}

.downSelect .search {
  margin-bottom: 0;
}

.downSelect .noItem {
  margin: 30px 0;
  color: var(--color-black4);
}

.listMax section div::before {
  content: '';
  background:var(--color-green);
  width: 7px;
  height: 7px;
  border-radius: 100px;
}

.listMax section div:nth-of-type(2)::before {
  background: var(--color-red4);
}

.maxWithdraw {
  margin-top: 20px;
}

.maxWithdraw h4 {
  font-size: 12px;
  margin-bottom: 5px;
  color: var(--color-black4);
}

.maxWithdraw section {
  background: var(--color-green4);
  border-radius: 100px;
  overflow: hidden;
  height: 8px;
  position: relative;
}

.maxWithdraw section span {
  display: block;
  position: absolute;
  background: var(--color-red4);
  border-radius: 100px;
  height: 100%;
  left: 0;
}

.inlinePrice {
  display: inline-block;
  direction: ltr;
}

.detailHistory {
  background: var(--color-white);
  border-radius: 10px;
}

.headerBox {
  height: 120px;
  background: var(--color-blue3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerBox::after {
  content: '';
  background: url('../svg/boxLogo.svg') no-repeat center / contain;
  width: 120px;
  height: 120px;
}

.stepData {
  padding: 20px;
}

.stepData div {
  display: flex;
  gap: 10px;
}

.stepData div span {
  background: var(--color-white);
  border: 2px solid var(--color-blue3);
  display: block;
  border-radius: 100px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stepData div span::after {
  content: '';
  background: var(--color-blue3);
  display: block;
  border-radius: 100px;
  width: 12px;
  height: 12px;
}

.stepData div h6 {
  font-weight: 500;
  color: var(--color-black4);
}

.stepData div h6 i {
  display: block;
  color: var(--color-gray5);
}

.stepData section {
  background: var(--color-blue3);
  height: 30px;
  width: 1px;
  margin: -18px 11px 2px 0;
}

.stepData div.step_process span,
.stepData div.step_checking span,
.stepData div.step_pending span {
  border-color: var(--color-yellow1);
}

.stepData div.step_process span::after,
.stepData div.step_checking span::after,
.stepData div.step_pending span::after {
  background: var(--color-yellow1);
}

.stepData div.step_process h6,
.stepData div.step_checking h6,
.stepData div.step_pending h6 {
  color: var(--color-yellow1);
}

.stepData div.step_complete span,
.stepData div.step_confirm span {
  border-color: var(--color-green4);
}

.stepData div.step_complete span::after,
.stepData div.step_confirm span::after {
  background: var(--color-green4);
}

.stepData div.step_complete h6,
.stepData div.step_confirm h6 {
  color: var(--color-green4);
}

.stepData div.step_close span,
.stepData div.step_reject span,
.stepData div.step_cancel span {
  border-color:var(--color-red);
}

.stepData div.step_close span::after,
.stepData div.step_reject span::after,
.stepData div.step_cancel span::after {
  background:var(--color-red);
}

.stepData div.step_close h6,
.stepData div.step_reject h6,
.stepData div.step_cancel h6 {
  color:var(--color-red);
}

.noteEmail {
  padding: 0 52px;
  font-weight: 500;
  font-size: 12px;
}

.noteEmail span {
  color: var(--color-blue3);
}

.borderHex {
  height: 1px;
  background: var(--color-white6);
  margin: 20px 30px 0;
}

.listCashCodeCustom {
  padding: 15px 30px 20px;
}

.listCashCodeCustom span:nth-of-type(1) {
  font-weight: 500;
  min-width: 70px;
}

.listCashCodeCustom section {
  border: none;
  padding: 5px 0;
}

.currencyDetail {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

.currencyDetail img {
  position: relative;
  top: -1px;
  transform: scale(1.15);
  left: 2px;
}

.gCopy {
  background: var(--color-grayLi3);
  border-radius: 8px;
  display: flex;
  gap: 10px;
  padding: 10px 15px;
}

.gCopy b {
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  direction: ltr;
  line-height: 22px;
  color: var(--color-black4);
}

.gCopy i {
  margin-top: 2px;
}

.chart {
  display: flex;
  mask-image: url('../svg/chart.svg');
  background-color: var(--color-black4);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.historyDetail {
  display: flex;
  flex: 1;
  gap: 5px;
  flex-direction: column;
  margin-top: 15px;
}

.historyDetail>div {
  display: flex;
  gap: 2px;
  background: var(--color-white4);
  border-radius: 7px;
  flex-direction: column;
  padding: 15px;
}

.historyDetail div section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
}

.historyDetail div section img {
  width: 18px;
  height: 18px;
  border-radius: 100px;
  background:var(--color-white8);
}

.historyDetail div section span:nth-of-type(1) {
  flex: 1;
  color:var(--color-black4);
}
.historyDetail div section span:nth-of-type(2) {
  color:var(--color-black4);
}


.historyDetail div section span b {
  color: var(--color-gray5);
}

.historyDetail div section:nth-of-type(n+2) span:nth-of-type(1) {
  color: var(--color-gray);
}

.chartView {
  height: 100%;
  overflow: hidden;
  background: var(--color-white);
}

.chartView iframe {
  height: 100%;
  width: 100%;
  border: none;
}

.listOfNetwork div {
  padding: 10px 0;
}

.listOfNetwork div h3 {
  line-height: 14px;
  color: var(--color-black4);
  /* color: var(--color-Onlywhite); */
}

.listOfNetwork div h3 span {
  font-size: 11px;
}

.codeAlert {
  background:var(--color-goldLi1);
  color: var(--color-orange1);
  border-radius: 10px;
  padding: 15px;
  align-items: flex-start;
  justify-content: center;
  display: flex;
  margin-top: 20px;
  gap: 10px;
}

.codeAlert::before {
  content: '';
  background: url('../svg/danger.svg') no-repeat center / contain;
  width: 21px;
  height: 21px;
}

.codeAlert p {
  flex: 1;
  font-size: 13px;
}


.codeDanger {
  background:var(--color-goldLi1);
  color:var(--color-gold);
  border-radius: 10px;
  padding: 15px;
  align-items: flex-start;
  justify-content: center;
  display: flex;
  margin-top: 20px;
  gap: 10px;
}

.codeDanger::before {
  content: '';
  background: url('../svg/danger1.svg') no-repeat center / contain;
  width: 21px;
  height: 21px;
}

.codeDanger p {
  flex: 1;
  font-size: 13px;
  color: var(--color-orange);
}


.preData {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.preLogo {
  background: url('../svg/preLogo.svg') no-repeat center / contain;
  width: 270px;
  height: 270px;
  margin-top: 20px;
}

.preData .largeButton {
  display: flex;
  background:var(--color-blue1);
  padding-left: 25px;
  padding-right: 25px;
  margin-bottom: 40px;
  gap: 8px;
}

.preData h4 {
  margin-top: 20px;
  font-size: 15px;
  color: var(--color-black4);
}

.preData .largeButton::before {
  content: '';
  background: url('../svg/pre.svg') no-repeat center / contain;
  width: 24px;
  height: 24px;
}

.rulesNoFlex {
  display: block;
}

.voucherDataColor {
  background: var(--color-white4);
  border-radius: 8px;
  margin: 10px 0 6px;
}

.dialogWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1000;
}

.dialogWrapper h6 {
  font-size: 14px;
  font-weight: bold;
  color: var(--color-black3);
  display: flex;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 10px;
}

.dialogWrapper p {
  color: var(--color-gray4);
  text-align: center;
  padding: 0 20px;
}

.dialogWrapper .logo {
  margin: 0;
}

.dialogWrapper section {
  display: flex;
  gap: 10px;
  margin-top: 40px;
}

.dialogWrapper section span {
  background-color:var(--color-blue3);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dialogWrapper section span.close {
  background-color: transparent;
  color: var(--color-gray2);
  border: none;
  padding: 10px;
  font-size: 14px;
  cursor: pointer;
  border: 1px dashed var(--color-white6);
  font-size: 12px;
}

.boxWrapper {
  background: var(--bg-main);
  border-radius: 12px;
  width: 90%;
  margin-top: 30px;
  padding: 15px 20px;
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.boxWrapper div {
  display: flex;
  gap: 3px;
}

.boxWrapper div:nth-of-type(1) i,
.boxWrapper div:nth-of-type(2) i {
  background: url('../svg/share.svg') no-repeat center / contain;
  width: 20px;
  height: 20px;
}

.boxWrapper div:nth-of-type(2) i {
  background-image: url('../svg/addHome.svg')
}

.dialogWrapper .largeButton {
  width: 90%;
}

.alertList {
  display: flex;
  gap: 10px;
  flex-direction: column;
  margin-top: 20px;
  flex: 1;
}

.alertList .topTitle {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 5px;
  border-bottom: 1px solid var(--color-white6);
  margin-bottom: 15px;
}

.alertList .topTitle .txt {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.alertList .topTitle .txt .t1 {
  font-size: 13px;
  color: var(--color-black4);
  display: flex;
}

.alertList .topTitle .txt .t1::before {
  content: '';
  background: var(--color-black);
  mask-image: url('../svg/gift.svg');
  -webkit-mask-image: url('../svg/gift.svg');
  mask-size: cover;
  -webkit-mask-size: cover;
  width: 18px;
  height: 18px;
  margin-left: 4px;
}

.alertList .topTitle .infoActive {
  font-size: 13px;
  color: var(--color-black4);
  display: flex;
}

.alertList .topTitle .infoActive::before {
  content: '';
  background:var(--color-blue2);
  mask-image: url('../svg/info.svg');
  -webkit-mask-image: url('../svg/info.svg');
  mask-size: cover;
  -webkit-mask-size: cover;
  width: 18px;
  height: 18px;
  margin-left: 4px;
}

.alertList .topTitle .txt .t2 {
  font-size: 12px;
  color: var(--color-gray2);
}

.alertList>div {
  border: 1px solid var(--color-white5);
  padding: 12px;
  border-radius: 12px;
}

.alertList>div span {
  border-bottom: 1px solid var(--color-white6);
  text-align: left;
  display: block;
}


.alertList .deleteIcon {
  display: inline-block;
  background: url('../svg/trash.svg') no-repeat center / contain;
  width: 24px;
  height: 24px;
}

.alertList h3 {
  background: var(--color-greenLi4);
  color: var(--color-green4);
  padding: 4px 10px 2px;
  display: inline-block;
  margin-top: 10px;
}

.alertList h4 {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  gap: 6px;
}

.alertList h4 b {
  color: var(--color-gray1);
}

.bestPrice {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 5px;
  color: var(--color-gray);
}

.bestPrice span {
  font-size: 11px;
}

.bestPrice p {
  direction: ltr;
}

.addGift input {
  background: var(--color-white);
  border: 1px solid var(--color-white6);
  border-radius: 5px;
  width: 100px;
  padding: 6px 8px;
  font-size: 12px;
  direction: ltr;
  text-align: right;
  color: var(--color-black4);
}

.addGift span:nth-of-type(2) {
  font-size: 12px;
  flex: 1;
  color: var(--color-black4);
}

.addGift p {
  display: block;
  background: url('../svg/add.svg') no-repeat center / contain;
  width: 20px;
  height: 20px;
  filter: brightness(50%) sepia(140) saturate(230) hue-rotate(142deg)
}

.needRender i,
.needRenderShow i {
  background:var(--color-white8);
  border-radius: 5px;
  margin-left: 4px;
  padding: 0 7px;
  direction: ltr;
  color: var(--color-black1);
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

.needRenderShow {
  margin-top: 5px;
}

.needRenderShow i {
  display: inline-block;
}

.needRender i::after {
  content: '';
  background: url('../svg/cancel.svg') no-repeat center / contain;
  width: 14px;
  height: 14px;
  display: inline-block;
}

.selectParentFix .downSelect {
  position: static;
  margin-right: -1px;
}

.priceChanged {
  opacity: 0.4 !important;
}