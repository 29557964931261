.offline-notification {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: fit-content;
    max-width: 500px;
    background-color: #f44336; /* رنگ قرمز هشدار */
    color: #fff;
    text-align: center;
    padding: 10px 30px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    animation: slideDown 0.5s ease-out;
    font-family: 'Arial', sans-serif;
    font-size: 14px;
  }
  
  @keyframes slideDown {
    0% {
      transform: translateX(-50%) translateY(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(-50%) translateY(0);
      opacity: 1;
    }
  }
  