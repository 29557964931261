/* @font-face {
  font-family: YekanBakhEn;
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/YekanBakh-Thin.ttf');
}

@font-face {
  font-family: YekanBakhEn;
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/YekanBakh-Light.ttf');
}

@font-face {
  font-family: YekanBakhEn;
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/YekanBakh-Regular.ttf');
}

@font-face {
  font-family: YekanBakhEn;
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/YekanBakh-SemiBold.ttf');
}

@font-face {
  font-family: YekanBakhEn;
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/YekanBakh-Bold.ttf');
}

@font-face {
  font-family: YekanBakhEn;
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/YekanBakh-ExtraBold.ttf');
}

@font-face {
  font-family: YekanBakhFa;
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/YekanBakh-Thin-Fa.ttf');
}

@font-face {
  font-family: YekanBakhFa;
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/YekanBakh-Light-Fa.ttf');
}

@font-face {
  font-family: YekanBakhFa;
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/YekanBakh-Regular-Fa.ttf');
}

@font-face {
  font-family: YekanBakhFa;
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/YekanBakh-SemiBold-Fa.ttf');
}

@font-face {
  font-family: YekanBakhFa;
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/YekanBakh-Bold-Fa.ttf');
}

@font-face {
  font-family: YekanBakhFa;
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/YekanBakh-ExtraBold-Fa.ttf');
}

@font-face {
  font-family: VazirmatnMedium;
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/Vazirmatn-Medium.ttf');
}

@font-face {
  font-family: 'Vazir-EnNum';
  font-style: normal;
  font-weight: normal;
  src: local('Vazir-EnNum'), url('../fonts/Vazir-EnNum.woff2') format('woff');
} */

/* @font-face {
  font-family: 'IRANYekanXVF';
  font-style: normal;
  font-weight: normal;
  src: local('IRANYekanXVF'), url('../fonts/IRANYekanXVF.woff2') format('woff');
} */

/* @font-face {
  font-family: YekanBakh;
  font-style: normal;
  font-weight: 400;
  src: local('YekanBakh'), url('../fonts/new/YekanBakh-Regular.5764935.woff2') format('woff');
}
@font-face {
  font-family: YekanBakh;
  font-style: normal;
  font-weight: 500;
  src: local('YekanBakh'), url('../fonts/new/YekanBakh-SemiBold.4040dd6.woff2') format('woff');
}
@font-face {
  font-family: YekanBakh;
  font-style: normal;
  font-weight: 600;
  src: local('YekanBakh'), url('../fonts/new/YekanBakh-Bold.7817543.woff2') format('woff');
} */

/* @font-face {
  font-family: YekanBakh;
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/new/IRANYekanXVF.ttf');
  unicode-range: U+0030-0039, U+0041-005A, U+0061-007A, U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E;
} */

@font-face {
  font-family: YekanBakh;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:url('../fonts/new/Vazir-EnNum-Regular22.woff2') format('woff');
  unicode-range: U+0030-0039, U+0041-005A, U+0061-007A, U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E;
}


@font-face {
  font-family: YekanBakh;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/new/YekanBakh-Regular.5764935.woff2') format('woff');
  unicode-range: U+0600-06FF, U+FB50-FDFF, U+06F0-06F9, U+0660-0669, U+200C, U+200D, U+060C, U+061B, U+061F, U+066A-066F;
}


@font-face {
  font-family: YekanBakhOlny;
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/YekanBakh-Regular-Fa.ttf');
}
