:root {
  --bg-main: #f4f6fb;
  --color-Onlywhite: #ffffff;
  --color-Onlyblack: #000;
  --color-blueToWhite: #2424da;
  --color-black: #000;
  --color-black1: #111;
  --color-black2: #1e1e1e;
  --color-black3: #333;
  --color-black4: #212529;
  --color-blackLi1: #292d3280;
  --color-blackLi2: #0000001f;
  --color-blackLi3: #00000033;
  --color-blackLi4: #25212980;
  --color-green: #08A56D;
  --color-green1: #00ff08;
  --color-green2: #45a049;
  --color-green3: #4caf50;
  --color-green4: #22c58b;
  --color-green5: #08A56D;
  --color-green6: #10dd95;
  --color-greenLi1: #ebfaf5;
  --color-greenLi2: #22c58b2b;
  --color-greenLi3: #22c58b17;
  --color-greenLi4: #22c58c32;
  --color-blue: #2424da;
  --color-newBlue: #2424da;
  --color-blue1: #0F67FE;
  --color-blue2: #1100ff;
  --color-blue3: #2424da;
  --color-blue4: #0000a5;
  --color-blue5: #1e88e5;
  --color-blue6: #2196f3;
  --color-blue7: #3159E8;
  --color-blue8: #2424da;
  --color-blue9: #2424da;
  --color-blue10: #2424da ;
  --color-blueLi1: #d3d3e9;
  --color-blueLi2: #dbdee3;
  --color-blueLi3: #e0e0f2;
  --color-blueLi4: #e2f1f2;
  --color-blueLi5: #e3e3f4;
  --color-blueLi6: #e7eafa;
  --color-blueLi7: #e9f6f7;
  --color-blueLi8: #ededfc;
  --color-blueLi9: #dbe7ff;
  --color-blueLi10: #9999bb;
  --color-blueLi11: #2424d266;
  --color-blueLi12: #2424d238;
  --color-blueLi13: #D6DCE7;
  --color-blueLi14: #777e90;
  --color-blueLi8ToBlue: #ededfc;
  --color-grayBlue: #7c8c9f;
  --color-graylogo: #b5b5b5;
  --color-gray: #555;
  --color-gray1: #666;
  --color-gray2: #6d6672;
  --color-gray3: #84868a;
  --color-gray4: #888;
  --color-gray5: #939393;
  --color-gray6: #9a959e;
  --color-gray7: #aaa;
  --color-gray8: #34383e;
  --color-gray9: #525252;
  --color-gray10: #696969;
  --color-gray11: #bbb;
  --color-gray12: #666;
  --color-gray13: #616173;
  --color-gray14: #808A97;
  --color-grayLi1: #6565655f;
  --color-grayLi2: #9a959ef1;
  --color-grayLi3: #eeeeee59;
  --color-white: #ffffff;
  --color-white1: #F0F0F0;
  --color-white2: #f4f5f6;
  --color-white3: #F6F6F6;
  --color-white4: #F7F7F7;
  --color-white5: #f2f2f2;
  --color-white6: #ddd;
  --color-white7: #e9e9e9;
  --color-white8: #eee;
  --color-white9: #e0e0e0;
  --color-white10: #e5e5e5;
  --color-white11: #efefef;
  --color-white12: #f4f5f6;
  --color-white13: #e5e5e5;
  --color-white14: #e1e1e1;
  --color-white15: #F8FAFF;
  --color-white16: #e7ecf4;
  --color-white17: #e7ecf4;
  --color-whiteLi1: #ffffffe8;
  --color-whiteLi2: #f0f0f3c4;
  --color-whiteLi3: #e7ecf496;
  --color-gold: #704f00;
  --color-goldLi1: #F8EDE3;
  --color-yellow: #F4C952;
  --color-yellow1: #FFB13B;
  --color-yellow3: #dbdf00;
  --color-red: #c52222;
  --color-red1: #b14848;
  --color-red2: #ff0000;
  --color-red3: #ff5e33;
  --color-red4: #FF6666;
  --color-red5: #d11c1c;
  --color-redLi1: #e8414217;
  --color-orange: #ee9700;
  --color-orange1: #ff6838;
  --color-orange2: #e8bc27;
  --color-Skeleton: #00000015;
  --color-bgDomain: #f4f5f6;
  --color-shadow: #0000000a;
  --color-blueDark: #03045E;
  --color-txt5: #5C5C65;
  --color-bgCir1: #eff2fa80;
  --color-bgCir2: #EFF2FA;
  --color-div1: #DEE7F3;
  --color-hoverNav2: #e7ecf4;
  --color-shadow2: #333639;
  --color-bghoverOrderBook: #3e454d;
  --color-divider5: #555e69;
  --color-hoverDeep: #e7eafa;
  


  --logo: url(../svg/logo.svg);


    /* ////////////////Notif */
    --bg: #ffffff;
    --text: #333;
    --box-bg: #fafafa;
    --shadow: #00000030;
    --btn-bg: #007bff;
    --btn-text: #ffffff;
    --btn-hover: #0056b3;
}

[data-theme="dark"] {
  --bg-main: #0d1117;
  --color-blueToWhite: #fff;
  --color-black: #fff;
  --color-black1: #f5f5f5;
  --color-black2: #e0e0e0;
  --color-black3: #ccc;
  --color-black4: #efefef;
  --color-blackLi1: #b5b5b580;
  --color-blackLi2: #ffffff1f;
  --color-blackLi3: #ffffff33;
  --color-blackLi4: #a3a5a8;
  --color-greenLi1: #163d31;
  --color-greenLi2: #1b704c;
  --color-greenLi3: #0f583a;
  --color-greenLi4: #16654c;
  --color-newBlue: #007bff;
  --color-blue1: #0064ff;
  --color-blue2: #0064ff;
  --color-blue3: #0049bb;
  --color-blue4: #0064ff;
  --color-blue5: #0064ff;
  --color-blue6: #0064ff;
  --color-blue7: #0064ff;
  --color-blue8: #0064ff;
  --color-blue9: #0064ff;
  --color-blue10: #3159e8 ;
  --color-blueLi1: #3a3a57;
  --color-blueLi2: #474b56;
  --color-blueLi3: #44446a;
  --color-blueLi4: #375658;
  --color-blueLi5: #3c3c68;
  --color-blueLi6: #2727af;
  --color-blueLi7: #2e4648;
  --color-blueLi8: #2727af;
  --color-blueLi9: #2727af;
  --color-blueLi10: #666688;
  --color-blueLi11: #a3a3ff66;
  --color-blueLi12: #a3a3ff38;
  --color-blueLi13: #9a9da3;
  --color-blueLi14: #a0a7b9;
  --color-blueLi8ToBlue: #0059ff;
  --color-grayBlue: #7c8c9f;
  --color-graylogo: #b5b5b5;
  --color-gray: #aaa;
  --color-gray1: #b1b1b1;
  --color-gray2: #b1b1b1;
  --color-gray3: #b1b1b1;
  --color-gray4: #b1b1b1;
  --color-gray5: #b1b1b1;
  --color-gray6: #bebebe;
  --color-gray7: #b1b1b1;
  --color-gray8: #2c2e33;
  --color-gray9: #c8c8c8;
  --color-gray10: #a7a7a7;
  --color-gray11: #969696;
  --color-gray12: #d1d1d1;
  --color-gray13: #c0c0d1;
  --color-gray14: #b7b7c1;
  --color-grayLi1: #4c4c4c5f;
  --color-grayLi2: #726c7df1;
  --color-grayLi3: #44444459;
  --color-white: #232323;
  --color-white1: #292929;
  --color-white2: #292a2b;
  --color-white3: #303030;
  --color-white4: #2f2f2f;
  --color-white5: #252525;
  --color-white6: #444;
  --color-white7: #383838;
  --color-white8: #333;
  --color-white9: #424242;
  --color-white10: #393939;
  --color-white11: #353535;
  --color-white12: #505253;
  --color-white13: #292b2e;
  --color-white14: #323232;
  --color-white15: #3c3d3e;
  --color-white16: #303030;
  --color-white17: #3d3d3d;
  --color-whiteLi1: #1e1e1ee8;
  --color-whiteLi2: #232326c4;
  --color-whiteLi3: #3d3d3d96;
  --color-goldLi1: #382c1a;
  --color-red5: #ff2020;
  --color-redLi1: #5d2828;
  --color-green: #00db8e;
  --color-Skeleton: #ffffff15;
  --color-bgDomain: #0064265d;
  --color-shadow: #ffffff0a;
  --color-blueDark: #ffffff;
  --color-txt5: #d8d8d8;
  --color-bgCir1: #21274280;
  --color-bgCir2: #212742;
  --color-div1: #303234;
  --color-hoverNav2: #2c2f36;
  --color-shadow2: #333639;
  --color-bghoverOrderBook: #dbe1e7;
  --color-divider5: #c0c5ca;
  --color-hoverDeep: #0d1117;




  --logo: url(../svg/exnovinLogoDark.svg);


    /* ////////////////Notif */
    --bg: #1e1e1e;
    --text: #f5f5f5;
    --box-bg: #393939;
    --shadow: #ffffff5c;
    /* --btn-bg: #ff9800; */
    --btn-text: #1e1e1e;
    /* --btn-hover: #e68900; */
}